//
import _get from 'lodash/get'
//
import { notifyProblem } from '@/services/notify'
import { archVotes } from '@/services/ArchitecturalVoting/ArchitecturalVote/store'
import { archReqs } from '@/services/Architectural/ArchitecturalSubmission/store'
//
import dataToInteger from '@/utilities/data/toInteger'
//
export const beforeMount = function() {
  const id = dataToInteger(_get(this, ['$route', 'params', 'id'], null))
  this.architecturalSubmissionID = id

  if (typeof id !== 'number' || id < 1) {
    notifyProblem('The id of this request could not be determined.')
    return
  }

  ;(async () => {
    const authUserType = this.authUserType

    if (authUserType === 'Account') {
      /*
        get the list of voters (and votes)
      */
      const { list } = await archVotes.dispatch('getList', {
        params: {
          hoaId: this.hoaId,
          architecturalSubmissionID: id
        }
      })
      if (list.length >= 1) {
        this.parseCommitteeDetails({
          list
        })
      }
      this.loading.committee = false
      this.loading.voteDetails = false
    }

    const { result } = await archReqs.dispatch('getDetails', {
      architecturalSubmissionID: id
    })
    this.parseRequestDetails({
      details: result
    })
    this.loading.requestSpecifications = false

    this.retrieveDocument()
    this.retrievePhotos()

    this.loading.requestSpecifications = false
  })()
}

/////////////

import { archReqAttachments } from '@/services/Architectural/ArchitecturalSubmissionAttachment/store'
import { getArchAttachment } from '@/services/Architectural/ArchitecturalSubmissionAttachment/GetAttachmentFile'
import { notifyProblem, notifyMessage, notifyWarning, notifyError } from '@/services/notify'
import { base64toBlob, downloadPdf } from '@/services/blobutility'
import $ from 'jquery'

export const methods = {
  async reload() {
    if (this.isDebug == true)
      console.debug(
        'architecturalSubmissionAttachment=' +
          JSON.stringify(this.architecturalSubmissionAttachment)
      )

    if (this.onlyPhotos && this.onlyPhotos === true) {
      this.uploadMimeTypes = 'image/jpeg, image/png, image/gif'
      this.uploadMimeText = 'an image: (PNG, JPEG, or GIF)'
    }

    if (
      this.architecturalSubmissionAttachment &&
      this.architecturalSubmissionAttachment.architecturalSubmissionAttachmentID &&
      this.architecturalSubmissionAttachment.architecturalSubmissionAttachmentID > 0
    ) {
      this.title = 'Update'

      this.description =
        this.architecturalSubmissionAttachment.description ||
        this.architecturalSubmissionAttachment.name
      if (
        this.architecturalSubmissionAttachment.documentID &&
        this.architecturalSubmissionAttachment.documentID != undefined
      ) {
        this.attachment = true
        this.loadCurrentDocument()
      }
    } else {
      this.title = 'Create'
      this.description = ''
      this.attachment = false
    }
  },

  async loadCurrentDocument() {
    if (
      !this.architecturalSubmissionAttachment ||
      !this.architecturalSubmissionAttachment.documentID ||
      this.architecturalSubmissionAttachment.documentID <= 0
    ) {
      return
    }

    this.searchTerm = this.architecturalSubmissionAttachment.mimeType.indexOf('application/pdf')

    try {
      await getArchAttachment({
        params: {
          architecturalSubmissionAttachmentID: this.architecturalSubmissionAttachment
            .architecturalSubmissionAttachmentID,
          asBase64: true
        }
      }).then(({ photo }) => {
        if (photo) {
          this.archReqAttachDocumentFile = photo
        }

        if (this.isDebug == true) console.debug('this.archReqAttachDocumentFile=' + photo)
        if (this.archReqAttachDocumentFile && this.archReqAttachDocumentFile != undefined) {
          if (this.isDebug == true)
            console.debug(
              'image or pdf............this.searchTerm:' +
                this.searchTerm +
                '............' +
                this.archReqAttachDocumentFile
            )

          if (this.searchTerm != -1) {
            this.base64pdf = this.archReqAttachDocumentFile
            this.imageContent = null
          } else {
            this.imageContent = this.archReqAttachDocumentFile
            this.base64pdf = null
          }
        }
      })
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
    }
  },

  async downloadPdf() {
    if (this.isDebug == true) console.debug('downloadPdf...')

    if (!this.base64pdf || this.base64pdf === undefined) {
      notifyProblem('There was a problem opening the document.')
    }

    try {
      const returned = this.base64pdf.replace('data:application/pdf;base64,', '')
      if (returned && returned != undefined) {
        if (this.isDebug == true) console.debug('base64pdf........................' + returned)
        const contentType = 'application/pdf'
        const blob = base64toBlob(returned, contentType)

        downloadPdf(blob)
        notifyMessage(`Successfully downloaded this document.`)
        return
      }
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
      notifyWarning('There was a problem downloading this document.')
    }
  },

  async updateFile() {
    if (
      this.architecturalSubmissionAttachment &&
      this.architecturalSubmissionAttachment != undefined
    ) {
      try {
        if (
          !this.architecturalSubmissionAttachment ||
          this.architecturalSubmissionAttachment == undefined
        ) {
          notifyError('There was a problem updating this document.')
          return
        }

        if (this.description == '') {
          notifyError('Description is a required field.')
          return
        }

        const payload = {
          architecturalSubmissionAttachmentID: this.architecturalSubmissionAttachment
            .architecturalSubmissionAttachmentID,
          architecturalSubmissionID: this.architecturalSubmissionID,
          name: this.architecturalSubmissionAttachment.name,
          description: this.description,
          mimeType: this.architecturalSubmissionAttachment.mimeType,
          documentID: this.architecturalSubmissionAttachment.documentID //documentID is required in this table
        }

        //Update
        await archReqAttachments.dispatch('updateDocument', {
          payload: payload,
          done: () => {
            this.imageContent = null
            this.base64pdf = null

            this.$emit('update:toggle', (this.documentToggle = !this.documentToggle))
            // auto-close modal
            $('#clickclose').click()
          }
        })
      } catch (e) {
        notifyError(e)
      }
      this.loading = false
    } else {
      notifyError('There was a problem removing this document.')
    }
  },

  open() {
    this.loading = false
  },

  closeModal() {
    this.leaveForm
  },

  confirmFinishEditingWithoutSaving() {
    this.$buefy.dialog.confirm({
      message: `You have unsaved changes. Are you sure you want to leave the form?`,
      confirmText: 'Yes',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        this.leaveForm()
      }
    })
  },

  leaveForm() {
    // auto-close modal
    this.$emit('update:toggle', (this.toggle = false))
    $('#clickclose').click()
    this.loading = false
  },

  async submitFile() {
    if (this.architecturalSubmissionID <= 0) {
      notifyError('There was a problem uploading this document.')
      return
    }

    if (this.description == '') {
      notifyError('Description is a required field.')
      return
    }

    this.loading = true

    try {
      const payload = {
        file: this.file,
        newFileName: this.file.name,
        existingArchitecturalSubmissionID: this.architecturalSubmissionID,
        description: this.description
      }

      if (this.attachment == false) {
        await archReqAttachments
          .dispatch('addDocument', {
            payload: payload
          })
          .then(({ returned }) => {
            this.base64pdf = ''
            if (returned && returned != undefined) {
              if (this.isDebug == true)
                console.debug('results.............................=' + JSON.stringify(returned))

              this.imageContent = null
              this.base64pdf = null

              this.$emit('update:toggle', (this.documentToggle = !this.documentToggle))
              // auto-close modal
              $('#clickclose').click()

              notifyMessage(`Successfully uploaded the document.`)
            } else {
              notifyError('There was a problem uploading this document.')
            }
          })
      }
    } catch (e) {
      notifyError(e)
    }
    this.loading = false
  },

  zoom(direction) {
    const amt = Math.sqrt(2)
    if (direction > 0) {
      this.scale *= amt
    } else {
      this.scale /= amt
    }

    // constrain to min/max
    this.scale = Math.max(0.5, Math.min(4, this.scale))
  }
}

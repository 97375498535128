<template>
  <div :style="styles_">{{ text }}</div>
</template>

<script>
/*
  import StatusTile from '@/components/tiles/Status'

  <StatusTile text="" :background="1">
*/

var backgrounds = {
  // e.g.
  //  pending arch request
  1: 'linear-gradient(90deg, #f76b1c, #f5a623)',

  // e.g.
  //  approved arch request
  2: 'linear-gradient(90deg, #3fe77b, #048d35)',

  // e.g.
  //  denied arch request
  3: 'linear-gradient(90deg, rgb(218, 126, 126), rgb(175, 63, 63))',

  //  disabled
  4: 'linear-gradient(295deg, #2f4353 0%, #d2ccc4 97%)',

  //  cancelled
  5: 'linear-gradient(90deg, #5c2626 0%, #473535 97%)'
}

export default {
  props: {
    styles: {
      type: Object,
      default: () => {
        return {}
      }
    },
    text: {
      type: String,
      default: ''
    },
    background: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      styles_: Object.assign(
        {},
        {
          background: backgrounds[this.background],
          padding: '6px 17px',
          borderRadius: '6px',
          color: '#fff',
          textAlign: 'center'
        },
        this.styles
      )
    }
  },
  watch: {
    background(value) {
      this.styles_ = Object.assign(
        {},
        {
          background: backgrounds[value],
          padding: '6px 17px',
          borderRadius: '6px',
          color: '#fff',
          textAlign: 'center'
        },
        this.styles
      )
    }
  }
}
</script>

/*
  await archStipulations.dispatch ('addOne', {
    architecturalVoteID,
    stipulation
  })
*/

import kms from '@/services/kms'
import { notifyProblem } from '@/services/notify'

/* eslint-disable no-empty-pattern */
export async function addOne({}, { architecturalVoteID, stipulation }) {
  /* eslint-enable no-empty-pattern */
  try {
    await kms.post(`/ArchitecturalVoting/ArchitecturalStipulation`, {
      architecturalVoteID,
      stipulation
    })
  } catch (exception) {
    notifyProblem(`An architectural stipulation could not be added.`)
    console.error(exception)
  }

  return
}

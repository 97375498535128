/*
	import { getArchAttachmentsList } from '@/services/Architectural/ArchitecturalSubmissionAttachment/List';

  const { list, successful, message } = await getArchAttachmentsList ({
    params: {
      hoaID,
      architecturalSubmissionID
    }
  });
  if (!successful) {
    console.error (message);
    return;
  }
*/

import _get from 'lodash/get'
import kms from '@/services/kms'

export async function getArchAttachmentsList({ params }) {
  let message = 'The architectural requests list could not be retrieved.'

  try {
    const result = await kms.get(`/Architectural/ArchitecturalSubmissionAttachment/List`, {
      params
    })

    let list = _get(result, 'results', [])
    if (!Array.isArray(list)) {
      list = []
    }

    console.debug('getArchAttachmentsList=' + JSON.stringify(result))

    return {
      successful: true,
      message,
      list
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    list: []
  }
}

/*
  import { statusTile } from '@/utilities/architectural/tile/status'

  const { status } = statusTile({statusText})
*/

import StatusTile from '@/components/tiles/Status'

export const statusTile = ({ statusText }) => {
  let text = ''
  let background = 1

  console.debug('in statusTile=' + statusText)

  if (statusText === 'Approved') {
    text = 'Approved'
    background = 2
  } else if (statusText === 'Denied') {
    text = 'Denied'
    background = 3
  } else if (statusText === 'Cancelled') {
    text = 'Cancelled'
    background = 5
  } else if (statusText === 'Pending') {
    text = 'Pending'
    background = 1
  } else {
    text = 'Pending'
    background = 1
  }

  return {
    text,
    background,
    status: {
      component: StatusTile,
      props: {
        text,
        background
      }
    }
  }
}

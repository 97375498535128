export function data() {
  return {
    categories: [],
    status: 'All',

    adder: {
      loading: false,
      show: false
    },

    table: {
      rows: [],
      loading: true
    }
  }
}

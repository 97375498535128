<template>
  <PageContentLayoutOnly>
    <!-- Voting Section -->
    <div id="architecturallist" class="columns" v-if="authUserType === 'Account'">
      <div class="column ">
        <div class="p-5 card" :style="{ overflow: 'visible' }">
          <div class="columns">
            <div class="column arch-request--vote-details" :style="{ flexGrow: 2 }">
              <div class="" role="region" aria-label="Vote Details" vote-details>
                <section
                  :style="{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    paddingBottom: '12px'
                  }"
                >
                  <h4 :style="{ margin: 0 }">Vote Details</h4>
                </section>

                <div class="scrollable-card">
                  <b-tabs v-model="voteDetailsTab" type="is-toggle">
                    <b-tab-item label="Approvals">
                      <div :style="{ padding: '6px 0' }">
                        <b-checkbox
                          v-model="voteDetailsCheckboxes"
                          native-value="filterVotesWithoutStipulations"
                          :style="{ paddingRight: '12px' }"
                        >
                          <span>Hide votes without stipulations</span>
                        </b-checkbox>

                        <b-checkbox
                          v-model="voteDetailsCheckboxes"
                          native-value="showApprovalNotes"
                          :style="{ paddingRight: '12px' }"
                        >
                          <span>Show Notes</span>
                        </b-checkbox>
                      </div>

                      <div :style="{ height: '586px', overflowY: 'auto' }">
                        <div
                          v-if="
                            committeeMembersApproved &&
                              committeeMembersApproved.length === 0 &&
                              voteDetailsCheckboxes.includes('filterVotesWithoutStipulations') ===
                                false
                          "
                          class="has-text-grey"
                          :style="{ textAlign: 'center', padding: '2em' }"
                        >
                          There are not any votes in favor of this request.
                        </div>

                        <div
                          v-if="
                            committeeMembersApproved &&
                              committeeMembersApproved.length === 0 &&
                              voteDetailsCheckboxes.includes('filterVotesWithoutStipulations') ===
                                true
                          "
                          class="has-text-grey"
                          :style="{ textAlign: 'center', padding: '2em' }"
                        >
                          No stipulations have been stated by approvers.
                        </div>

                        <b-message
                          v-for="(member, index) in committeeMembersApproved"
                          :key="index"
                          :style="{ marginRight: '12px' }"
                        >
                          <div :style="{ fontSize: '1.2rem' }">{{ member.name }}</div>

                          <div v-if="member.stipulations.length >= 1" :style="{ padding: '6px 0' }">
                            <div :style="{ fontWeight: 'bold' }">Stipulations</div>
                            <div v-for="(stipulation, index) in member.stipulations" :key="index">
                              <div>{{ stipulation.text }}</div>
                            </div>
                          </div>

                          <div
                            v-if="
                              member.notes.length >= 1 &&
                                voteDetailsCheckboxes.includes('showApprovalNotes')
                            "
                            :style="{ padding: '6px 0' }"
                          >
                            <div :style="{ fontWeight: 'bold' }">Notes</div>
                            <div v-for="(note, index) in member.notes" :key="index">
                              <div>{{ note.text }}</div>
                            </div>
                          </div>
                        </b-message>
                      </div>
                    </b-tab-item>
                    <b-tab-item label="Denials">
                      <div :style="{ padding: '6px 0' }">
                        <b-checkbox
                          v-model="voteDetailsCheckboxes"
                          native-value="showDenialNotes"
                          :style="{ paddingRight: '12px' }"
                        >
                          <span>Show Notes</span>
                        </b-checkbox>
                      </div>

                      <div :style="{ height: '586px', overflowY: 'auto' }">
                        <div
                          v-if="committeeMembersDenied && committeeMembersDenied.length === 0"
                          class="has-text-grey"
                          :style="{ textAlign: 'center', padding: '2em' }"
                        >
                          There are not any votes against this architectural request.
                        </div>

                        <b-message
                          v-for="(member, index) in committeeMembersDenied"
                          :key="index"
                          :style="{ marginRight: '12px' }"
                        >
                          <div :style="{ fontSize: '1.2rem' }">{{ member.name }}</div>

                          <div
                            v-if="member.reasonForDenial.length >= 1"
                            :style="{ padding: '6px 0' }"
                          >
                            <div :style="{ fontWeight: 'bold' }">Reason for Denial</div>
                            <div>
                              {{ member.reasonForDenial }}
                            </div>
                          </div>

                          <div
                            v-if="
                              member.notes.length >= 1 &&
                                voteDetailsCheckboxes.includes('showDenialNotes')
                            "
                            :style="{ padding: '6px 0' }"
                          >
                            <div :style="{ fontWeight: 'bold' }">Notes</div>
                            <div v-for="(note, index) in member.notes" :key="index">
                              <div>{{ note.text }}</div>
                            </div>
                          </div>
                        </b-message>
                      </div>
                    </b-tab-item>
                    <b-tab-item label="Final Denials">
                      <div class="tile is-parent is-12">
                        <article class="tile is-child notification is-12">
                          <div class="container" v-if="cardcolumns && cardcolumns1 != undefined">
                            <div class="col" v-for="(column, index) in cardcolumns1" :key="index">
                              <div
                                class="item-container"
                                v-for="(item, index) in Object.keys(column)"
                                :key="index"
                              >
                                <div class="card" v-if="column[item] && column[item] != undefined">
                                  <span>
                                    <div class="card-content">
                                      <div class="media">
                                        <div
                                          class="media-content"
                                          style="overflow: hidden !important;"
                                        >
                                          <p class="font-bold is-12">
                                            <span>{{ `${column[item].committeeName || ''}` }}</span
                                            ><span class="float-right">
                                              <b-button
                                                :disabled="
                                                  isDeniable !== true ||
                                                    typeof requestDetails.wasApproved === 'boolean'
                                                "
                                                class="is-small font-small is-link is-light"
                                                @click.prevent="addDenialModal(column[item])"
                                                >Add</b-button
                                              >
                                            </span>
                                          </p>
                                          <p
                                            v-if="
                                              column[item].text && column[item].text !== undefined
                                            "
                                            class="is-12"
                                          >
                                            <span
                                              ><label class="label">{{
                                                `${column[item].text || ''}`
                                              }}</label></span
                                            >
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="font-bold p-5">Final Denials</div>
                          <div
                            style="padding-bottom:10px;"
                            v-for="(finaldenial, index) in finalDenials"
                            :key="index"
                          >
                            <div class="columns" v-if="finaldenial">
                              <div class="column is-9 pl-6">
                                <li>{{ `${finaldenial}` }}</li>
                              </div>
                              <div class="column is-3">
                                <span class="pl-6">
                                  <b-button
                                    :disabled="
                                      isDeniable !== true ||
                                        typeof requestDetails.wasApproved === 'boolean'
                                    "
                                    class="is-small font-small is-right"
                                    @click.prevent="confirmDeleteFinalDenial(finaldenial)"
                                    >Delete</b-button
                                  ></span
                                >
                              </div>
                            </div>
                          </div>
                        </article>
                      </div>
                    </b-tab-item>
                    <b-tab-item label="Final Stipulations">
                      <div class="tile is-parent is-12">
                        <article class="tile is-child notification is-12">
                          <div class="container" v-if="cardcolumns && cardcolumns != undefined">
                            <div class="col" v-for="(column, index) in cardcolumns" :key="index">
                              <div
                                class="item-container"
                                v-for="(item, index) in Object.keys(column)"
                                :key="index"
                              >
                                <div class="card" v-if="column[item] && column[item] != undefined">
                                  <span>
                                    <div class="card-content">
                                      <div class="media">
                                        <div
                                          class="media-content"
                                          style="overflow: hidden !important;"
                                        >
                                          <p class="font-bold is-12">
                                            <span>{{ `${column[item].committeeName || ''}` }}</span
                                            ><span class="float-right">
                                              <b-button
                                                :disabled="
                                                  isApprovable !== true ||
                                                    typeof requestDetails.wasApproved === 'boolean'
                                                "
                                                class="is-small font-small is-link is-light"
                                                @click.prevent="addStipulationModal(column[item])"
                                                >Add</b-button
                                              >
                                            </span>
                                          </p>
                                          <p
                                            v-if="
                                              column[item].text && column[item].text !== undefined
                                            "
                                            class="is-12"
                                          >
                                            <span
                                              ><label class="label">{{
                                                `${column[item].text || ''}`
                                              }}</label></span
                                            >
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="font-bold p-5">Final Stipulations</div>
                          <div
                            style="padding-bottom:10px;"
                            v-for="(finalstipulation, index) in finalStipulations"
                            :key="index"
                          >
                            <div class="columns" v-if="finalstipulation">
                              <div class="column is-9 pl-6">
                                <li>{{ `${finalstipulation}` }}</li>
                              </div>
                              <div class="column is-3">
                                <span class="pl-6">
                                  <b-button
                                    :disabled="
                                      isApprovable !== true ||
                                        typeof requestDetails.wasApproved === 'boolean'
                                    "
                                    class="is-small font-small is-right"
                                    @click.prevent="confirmDeleteFinalStipulation(finalstipulation)"
                                    >Delete</b-button
                                  ></span
                                >
                              </div>
                            </div>
                          </div>
                        </article>
                      </div>
                    </b-tab-item>
                  </b-tabs>
                </div>
                <b-loading
                  :is-full-page="false"
                  v-model="loading.voteDetails"
                  :can-cancel="false"
                ></b-loading>
              </div>
            </div>

            <div class="column">
              <b-tooltip
                :label="conclusionLabel"
                position="is-bottom"
                :active="
                  (isDeniable === false && isApprovable === false) ||
                    typeof requestDetails.wasApproved === 'boolean'
                "
                :style="{ width: '100%' }"
                multilined
              >
                <div class="" :style="{ marginBottom: '12px', overflow: 'visible' }">
                  <div
                    :style="{
                      display: 'inline-block',
                      marginLeft: '-5px',
                      textAlign: 'left',
                      width: '50%',
                      padding: '0 6px'
                    }"
                  >
                    <b-button
                      type="is-primary"
                      outlined
                      :style="{ width: '100%' }"
                      @click="denyRequest"
                      :disabled="
                        isDeniable !== true || typeof requestDetails.wasApproved === 'boolean'
                      "
                      >Deny</b-button
                    >
                  </div>

                  <div :style="{ display: 'inline-block', width: '50%', padding: '0 6px' }">
                    <b-button
                      type="is-primary"
                      outlined
                      :style="{ width: '100%' }"
                      @click="approveRequest"
                      :disabled="
                        isApprovable !== true || typeof requestDetails.wasApproved === 'boolean'
                      "
                      >Approve</b-button
                    >
                  </div>
                </div>
              </b-tooltip>

              <div
                class=""
                role="region"
                aria-label="Voting Committee"
                vote-tallies
                :style="{ marginBottom: '12px', paddingBottom: '4px' }"
              >
                <h4 :style="{ margin: 0 }">Vote Tallies</h4>
                <section
                  :style="{
                    display: 'inline-flex',
                    paddingTop: '12px',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }"
                >
                  <b-taglist attached :style="{ display: 'inline-block', margin: 0 }">
                    <b-tag type="is-primary is-light" :style="{ fontWeight: 'bold' }">{{
                      results.yes
                    }}</b-tag>
                    <b-tag type="is-light">For</b-tag>
                  </b-taglist>

                  <b-taglist attached :style="{ display: 'inline-block', margin: 0 }">
                    <b-tag type="is-primary is-light" :style="{ fontWeight: 'bold' }">{{
                      results.no
                    }}</b-tag>
                    <b-tag type="is-light">Against</b-tag>
                  </b-taglist>

                  <b-taglist attached :style="{ display: 'inline-block', margin: 0 }">
                    <b-tag type="is-primary is-light" :style="{ fontWeight: 'bold' }">{{
                      results.pending
                    }}</b-tag>
                    <b-tag type="is-light">Pending</b-tag>
                  </b-taglist>
                </section>
              </div>

              <div class="" role="region" aria-label="Voting Committee" voting-committee>
                <h4 :style="{ margin: 0 }">Voting Committee</h4>

                <section
                  class="scrollable-card"
                  :style="{
                    padding: '12px 0',
                    width: '100%',
                    height: '500px',
                    marginTop: '12px',
                    overflowY: 'auto'
                  }"
                >
                  <div v-if="typeof requestDetails.wasApproved !== 'boolean'">
                    <div
                      voter-panel
                      v-for="(member, index) in committeeMembers"
                      :key="index"
                      :style="{ marginBottom: '18px' }"
                    >
                      <b-tooltip
                        :style="{ width: '100%' }"
                        :delay="500"
                        position="is-bottom"
                        :active="member.isAuthenticated !== true ? true : false"
                        :label="'This vote may only be cast by ' + member.name"
                        size="is-small"
                        multilined
                      >
                        <div>{{ member.name }}</div>
                        <b-button
                          type="is-primary"
                          :disabled="member.isAuthenticated === true ? false : true"
                          :outlined="member.decision === true || member.decision === null"
                          :style="{ marginRight: '6px', textAlign: 'left' }"
                          @click="openDenialModal({ member })"
                          >Deny</b-button
                        >
                        <b-button
                          type="is-primary"
                          :disabled="member.isAuthenticated === true ? false : true"
                          :outlined="member.decision === false || member.decision === null"
                          :style="{ marginRight: '6px' }"
                          @click="openApprovalModal({ member })"
                          >Approve</b-button
                        >
                      </b-tooltip>
                    </div>
                  </div>

                  <div v-if="typeof requestDetails.wasApproved === 'boolean'">
                    <div
                      voter-panel-concluded
                      v-for="(member, index) in committeeMembers"
                      :key="index"
                      :style="{ marginBottom: '18px' }"
                    >
                      <b-tooltip
                        :style="{ width: '100%' }"
                        :delay="500"
                        position="is-bottom"
                        :label="
                          `Voting is closed. This request has already been ${
                            requestDetails.wasApproved ? 'approved' : 'denied'
                          }.`
                        "
                        size="is-small"
                        multilined
                      >
                        <div>{{ member.name }}</div>
                        <b-button
                          type="is-primary"
                          :disabled="true"
                          :outlined="member.decision === true || member.decision === null"
                          :style="{ marginRight: '6px' }"
                          >Deny</b-button
                        >
                        <b-button
                          type="is-primary"
                          :disabled="true"
                          :outlined="member.decision === false || member.decision === null"
                          :style="{ marginRight: '6px' }"
                          >Approve</b-button
                        >
                      </b-tooltip>
                    </div>
                  </div>
                </section>

                <b-loading
                  :is-full-page="false"
                  v-model="loading.committee"
                  :can-cancel="false"
                ></b-loading>

                <Specifications
                  v-show="false"
                  ref="specifications"
                  :requestDetails="requestDetails"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal :toggle.sync="approvalModal.active" :loading="approvalModal.loading">
      <template v-slot:header>Approval Details</template>

      <ValidationObserver ref="approvalModalObserver">
        <ValidInput
          v-model="approvalModal.data.note"
          rules="max:8000"
          name="Note"
          label="Note"
          maxlength="8000"
          type="textarea"
          vid="note"
          spellcheck="true"
          aria-label="Note"
        />
      </ValidationObserver>

      <div :style="{ paddingTop: '24px', paddingBottom: '34px' }">
        <b-field label="Stipulations" grouped>
          <b-input
            v-model="approvalModal.stipulation"
            expanded
            aria-label="add stipulation"
          ></b-input>
          <b-button @click="addStipulation()">Add</b-button>
        </b-field>

        <div :style="{ position: 'relative' }">
          <div
            :style="{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '40px',

              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '3px 12px'
            }"
          >
            <div :style="{ flexBasis: '40%', fontWeight: 'bold' }">Stipulation</div>
            <div :style="{ flexBasis: '40%', fontWeight: 'bold' }">Date</div>
            <div :style="{ flexBasis: '20%' }"></div>
          </div>
          <div
            :style="{
              display: 'flex',
              height: '40px',
              width: '100%'
            }"
          ></div>

          <div :style="{ position: 'relative', height: '140px', overflowY: 'scroll' }">
            <div
              v-for="(stipulation, index) in approvalModal.data.stipulations"
              :key="index"
              :style="{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '3px 12px',

                ...(index !== 0
                  ? {
                      borderTop: '1px solid #f0e8e8'
                    }
                  : {})
              }"
            >
              <div :style="{ flexBasis: '40%' }">{{ stipulation.text }}</div>
              <div :style="{ flexBasis: '40%' }">{{ stipulation.date }}</div>
              <div :style="{ flexBasis: '20%' }">
                <b-button @click="deleteStipulation(index)" size="is-small" rounded
                  >Delete</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <template v-slot:footer>
        <div
          :style="{
            display: 'flex',
            justifyContent: 'end',
            paddingRight: '18px',
            paddingBottom: '9px',
            paddingTop: '9px'
          }"
        >
          <b-button @click="approvalModalSave()" type="is-primary">Vote to Approve</b-button>
        </div>
      </template>
    </Modal>

    <Modal :toggle.sync="denialModal.active">
      <template v-slot:header>Denial Details</template>

      <ValidationObserver ref="denialModalObserver">
        <ValidInput
          v-model="denialModal.data.note"
          rules="max:8000"
          maxlength="8000"
          name="Note"
          label="Note"
          type="textarea"
          vid="note"
          spellcheck="true"
          aria-label="Note"
        />

        <ValidInput
          v-model="denialModal.data.reason"
          rules="required|max:8000"
          maxlength="8000"
          name="Reason"
          label="Reason"
          type="textarea"
          vid="Reason"
          spellcheck="true"
          aria-label="Reason"
        />
      </ValidationObserver>

      <template v-slot:footer>
        <div
          :style="{
            display: 'flex',
            justifyContent: 'end',
            paddingRight: '18px',
            paddingBottom: '9px',
            paddingTop: '9px'
          }"
        >
          <b-button @click="denialModalSave()" type="is-primary">Vote to Deny</b-button>
        </div>
      </template>
    </Modal>
    <Modal :toggle.sync="toggle">
      <StipulationModal
        :originalStipulation="selectedStipulation"
        :architecturalSubmission="currentArchitecturalSubmission"
      />
    </Modal>
    <Modal :toggle.sync="toggleDenial">
      <DenialReasonModal
        :originalDenial="selectedDenial"
        :architecturalSubmission="currentArchitecturalSubmission"
      />
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import Modal from '@/components/Modal'
import DenialReasonModal from './components/DenialReasonModal'
import StipulationModal from './components/StipulationModal'
import { methods } from './keys/methods'
import { data } from './keys/data'
import { beforeMount } from './keys/beforeMount'
import { computed } from './keys/computed'
import { ValidationObserver } from 'vee-validate'
import ValidInput from '@/components/inputs/ValidInput'
import Specifications from '../../Details/components/Specifications'

export default {
  name: 'ArchitecturalRequestVoteDetails',

  components: {
    PageContentLayoutOnly,
    Modal,
    StipulationModal,
    DenialReasonModal,
    ValidInput,
    ValidationObserver,
    Specifications
  },

  data,
  methods,
  beforeMount,
  computed,

  watch: {
    async toggle() {
      if (this.toggle == false) {
        await this.refreshFinalJSON()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    },
    async toggleDenial() {
      if (this.toggleDenial == false) {
        await this.refreshFinalJSON()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  i18n: {
    messages: {
      en: { violationConversations: { title: 'Conversations' } }
    }
  }
}
</script>

<style lang="scss" scoped></style>

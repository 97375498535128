import cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'

export const data = function() {
  const approvalModalPresets = {
    member: {},
    active: false,
    loading: true,
    stipulation: '',
    stipulationDeletions: [],

    //
    // maybe this shouldn't be a number??
    notesCount: 0,

    data: {
      note: '',
      stipulations: []
    }
  }

  const denialModalPresets = {
    active: false,
    loading: true,
    data: {
      note: '',
      reason: ''
    }
  }

  const authUserType = _get(this, ['$store', 'getters', 'user/authUserType'], null)
  // HomeOwner
  // Account (Management)

  return {
    lGet: _get,

    isConcludable: false,
    isApprovable: false,
    isDeniable: false,
    hasDocument: false,
    documentID: 0,
    isDebug: true,
    //
    // status: null,
    //
    authUserType,
    //
    //
    architecturalSubmissionID: null,

    doc: {
      content: ''
    },
    photos: [],

    loading: {
      requestSpecifications: true,
      committee: true,
      voteDetails: true,
      doc: true,
      photos: true
    },

    photoModal: {
      open: false,
      src: ''
    },

    requestDetails: {
      wasApproved: '?'
    },
    committeeMembers: [],
    results: {
      yes: 0,
      no: 0,
      pending: 0
    },
    //
    //
    approvalModal: cloneDeep(approvalModalPresets),
    approvalModalPresets,
    //
    denialModal: cloneDeep(denialModalPresets),
    denialModalPresets,
    //
    docModal: {
      active: false
    },
    imageModal: {
      active: false
    },
    pdfUploader: {
      active: false,
      loading: false
    },
    photoUploader: {
      active: false,
      loading: false
    },
    //
    voteDetailsTab: 0,

    // voteDetailsCheckboxes: [ 'notes', 'reasonForDenial', 'filterVotesWithoutStipulations' ],
    voteDetailsCheckboxes: ['showDenialNotes', 'showApprovalNotes', 'reasonForDenial'],

    voteDetailSortOptions: ['Approved ↑', 'Denied ↑'],
    voteDetailSorting: null,
    //
    editMode: false,
    ownerId: null
  }
}

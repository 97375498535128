<template>
  <div arch-request-details>
    <div class="level pt-5">
      <div class="level-left level-item title">
        {{ $t('architecturalRequestDetails.title') + ` ID: ${architecturalSubmissionID}` }}
      </div>
      <div class="level-right level-item" :style="{ display: 'flex', alignItems: 'center' }">
        <svg
          width="30"
          height="30"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 19l-7-7 7-7"
          ></path>
        </svg>
        <b-button label="Back to Architectural Requests" @click.prevent="returnToParent()" />
      </div>
    </div>
    <div class="card" :style="{ overflow: 'visible' }">
      <div
        :style="{ position: 'relative' }"
        role="region"
        aria-label="Architectural Request Details"
      >
        <b-button
          type="is-primary is-rounded"
          v-if="editMode"
          :style="{
            position: 'absolute',
            right: '70px',
            top: '10px'
          }"
          @click="saveEdits"
          >Save Edits</b-button
        >

        <div
          v-if="authUserType === 'Account'"
          :style="{
            position: 'absolute',

            top: '10px',
            right: '10px'
          }"
        >
          <b-tooltip
            class="is-info"
            :label="editMode ? 'Finish editing without saving' : 'Edit request'"
            position="is-left"
          >
            <button
              edit-request-details
              :aria-label="editMode ? 'finish editing without saving' : 'edit request details'"
              class="icon-container is-info"
              :style="{
                position: 'relative',
                display: 'flex',

                /* top: '10px',
                right: '10px',*/

                height: '40px',
                width: '40px',

                justifyContent: 'center',
                alignItems: 'center'
              }"
              @click="openEditMode()"
            >
              <div
                :class="editMode ? 'icon-x' : 'button is-primary is-rounded button-width'"
                :style="{
                  position: 'relative',
                  backgroundSize: 'cover',
                  ...(editMode
                    ? {
                        height: '50%',
                        width: '50%'
                      }
                    : {
                        height: '100%',
                        width: '100%'
                      })
                }"
              >
                {{ editMode ? '' : 'Edit' }}
              </div>
            </button>
          </b-tooltip>
        </div>
      </div>

      <SpecificationPanel label="Status">
        <div>
          <StatusTile
            v-if="requestDetails.wasApproved === null && requestDetails.cancelledDate === null"
            text="Pending"
            :background="1"
          />
          <StatusTile v-if="requestDetails.wasApproved === true" text="Approved" :background="2" />
          <StatusTile
            v-if="requestDetails.wasApproved === false || requestDetails.cancelledDate !== null"
            text="Denied"
            :background="3"
          />
        </div>
      </SpecificationPanel>

      <Specifications ref="specifications" :requestDetails="requestDetails" :editMode="editMode" />

      <div class="columns" :style="{ marginTop: '12px' }">
        <!-- document         -->
        <div class="column">
          <div class="card" :style="{ position: 'relative', height: '224px' }">
            <div
              :style="{
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: '8px'
              }"
            >
              <h4 :style="{ margin: 0 }">Document</h4>

              <div v-if="editMode">
                <b-button v-if="doc && doc.content" @click="openPDFUploader">Replace</b-button>
                <b-button v-else @click="openPDFUploader">Upload</b-button>
              </div>
            </div>

            <div
              v-if="lGet(doc, ['content', 'length'], '') >= 1 && loading.doc === false"
              @click="openPDFModal"
              :style="{
                position: 'relative',
                maxHeight: '180px',
                overflow: 'hidden',
                boxShadow: '0 12px 18px 0 rgba(0, 0, 0, 0.05)',
                borderRadius: '4px',
                cursor: 'pointer'
              }"
            >
              <PDFTile v-if="doc" :src="doc.content" />

              <!-- <button
                v-if="editMode"
                :style="{ 
                  position: 'absolute', 
                  top: '10px', 
                  left: '10px' 
                }"
                class="delete"
                type="button"
                @click="erasePDF ()"
              /> -->
            </div>
            <div v-if="loading.doc === false && lGet(doc, ['content', 'length'], '') === 0">
              A document wasn't included with this request.
            </div>

            <b-loading v-model="loading.doc" :is-full-page="false"></b-loading>
          </div>
        </div>

        <!-- photos         -->
        <div v-show="false" class="column" :style="{ flexGrow: 3 }">
          <div class="card" :style="{ position: 'relative', height: '224px' }">
            <div
              :style="{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: '8px'
              }"
            >
              <h4 :style="{ margin: 0 }">Photos</h4>
              <b-button v-if="editMode" @click="openPhotoUploader">Upload</b-button>
            </div>

            <!-- photos -->
            <div v-show="false" class="column" :style="{ flexGrow: 3 }">
              <div class="card" :style="{ position: 'relative', height: '224px' }">
                <div
                  :style="{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: '8px'
                  }"
                >
                  <h4 :style="{ margin: 0 }">Photos</h4>
                  <b-button v-if="editMode" @click="openPhotoUploader">Upload</b-button>
                </div>

                <button
                  v-if="editMode"
                  :style="{
                    position: 'absolute',
                    top: '10px',
                    left: '10px'
                  }"
                  class="delete"
                  type="button"
                  @click="erasePhoto({ index })"
                />
              </div>
            </div>
            <div v-if="Array.isArray(photos) && photos.length === 0 && loading.photos === false">
              No photos were uploaded.
            </div>

            <b-loading v-model="loading.photos" :is-full-page="false"></b-loading>
          </div>
        </div>
      </div>

      <b-loading
        :is-full-page="false"
        v-model="loading.requestSpecifications"
        :can-cancel="false"
      ></b-loading>
    </div>

    <PDFModal ref="pdfModal" />

    <b-modal v-model="photoModal.open" :style="{ zIndex: 999 }">
      <p class="image is-4by3">
        <img :src="photoModal.src" />
      </p>
    </b-modal>

    <Modal :toggle.sync="photoUploader.active" :loading="photoUploader.loading">
      <template v-slot:header>Photo Uploader</template>
      <template v-slot:footer>
        <div :style="{ display: 'flex', justifyContent: 'end' }">
          <b-button @click="uploadNewPhoto">Upload</b-button>
        </div>
      </template>

      <PhotoUploader :update="updatePhotos" />
    </Modal>

    <Modal :toggle.sync="pdfUploader.active" :loading="pdfUploader.loading">
      <template v-slot:header>PDF Uploader</template>

      <template v-slot:footer>
        <div class="column is-12">
          <valid-input
            style="width:50px !important;"
            name="uploadDescription"
            label="Description"
            maxlength="8000"
            type="textarea"
            vid="uploadDescription"
            placeholder="Description"
            spellcheck="true"
            aria-label="Description"
            rules="max:8000|required"
            class="is-small"
            v-model="uploadDescription"
          />
        </div>
        <div :style="{ display: 'flex', justifyContent: 'end' }">
          <b-button v-if="doc && doc.content" @click="uploadNewPDF">Replace Current PDF</b-button>
          <b-button v-else @click="uploadNewPDF">Upload</b-button>
        </div>
      </template>

      <PDFUploader :update="updatePDF" />
    </Modal>

    <Modal :toggle.sync="docModal.active">
      <template v-slot:header>Documents</template>

      <DocModal :architecturalSubmissionID="architecturalSubmissionID"> </DocModal>
    </Modal>

    <Modal :toggle.sync="imageModal.active">
      <template v-slot:header>Images</template>

      <ImageModal :architecturalSubmissionID="architecturalSubmissionID"> </ImageModal>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal'
import DocModal from '@/components/DocumentModal'
import ImageModal from '@/components/ImageModal'
import StatusTile from '@/components/tiles/Status'
import PDFModal from '@/components/Modals/PDF'
import PDFUploader from '@/components/Fields/PDFUploader'
import PhotoUploader from '@/components/Fields/PhotoUploader'
import Specifications from './components/Specifications'
import SpecificationPanel from './components/Specification/SpecificationPanel'
import { beforeMount } from './keys/beforeMount'
import { computed } from './keys/computed'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { watch } from './keys/watch'
import PDFTile from '@/components/tiles/PDF'
import ValidInput from '@/components/inputs/ValidInput'

export default {
  components: {
    PDFModal,
    PDFUploader,
    PhotoUploader,
    DocModal,
    ImageModal,
    Modal,
    PDFTile,
    Specifications,
    SpecificationPanel,
    StatusTile,
    ValidInput
  },

  data,
  methods,
  watch,
  computed,

  beforeMount,

  i18n: {
    messages: {
      en: { architecturalRequestDetails: { title: 'Architectural' } }
    }
  }
}
</script>

<style lang="scss">
.arch-request--vote-details {
  .message-body {
    border: 0;
  }

  .tab-content {
    padding: 12px 0 0;
  }
}
</style>

<style lang="scss" scoped>
.card {
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.05) !important;
  padding: 12px;

  .card {
    border: 0 !important;
  }
}
.button-width {
  min-width: 60px !important;
}
</style>

/*
  import { archVotes } from '@/services/ArchitecturalVoting/ArchitecturalVote/store'

  const { list } = archStipulations.dispatch ('getList') ({
    params: {
      hoaID,

      // optional
      architecturalSubmissionID,
      architecturalVoteID,
    }
  });
*/

/*
  possible structure (example):

    list = {
      // architecturalSubmissionID
      117: {

      }
    }
*/

import Vuex from 'vuex'

import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

const actions = {
  /* eslint-disable no-empty-pattern */

  /*
    const { list } = await archVotes.dispatch ('getList', {
      params: {
        hoaID: hoaId,
        architecturalSubmissionID
      }
    })
  */
  async getList({}, { params }) {
    try {
      const { results } = await kms.get('/ArchitecturalVoting/ArchitecturalVote/List', {
        params
      })

      return {
        list: results
      }
    } catch (exception) {
      notifyProblem(exception)
    }

    return {
      list: []
    }
  },

  /*
    await archVotes.dispatch ('UpdateVoteOnly', {
      json: {
        "hoaID": , // Number
        "architecturalVoteID": , // Number
        "committeeMemberID": , // Number

        "disapproveReason": "",
        "voteNote": "",
        "wasApproved": false
      }
    })
  */
  async UpdateVoteOnly({}, { json }) {
    try {
      await kms.put('/ArchitecturalVoting/ArchitecturalVote/UpdateVoteOnly', json)
    } catch (exception) {
      notifyProblem(exception)
    }

    return
  }

  /* eslint-enable no-empty-pattern */
}
const getters = {}

export const archVotes = new Vuex.Store({
  state: {},
  mutations: {},
  actions,
  getters
})

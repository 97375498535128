/*
  import { archReqAttachments } from '@/services/Architectural/ArchitecturalSubmissionAttachment/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyProblem, notifyMessage, notifyError } from '@/services/notify'
import kms from '@/services/kms'

import { storeArchAttachment } from '@/services/Architectural/ArchitecturalSubmissionAttachment/StoreAttachment'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    await archReqAttachments.dispatch ('StoreAttachment', {
      params: {
        // optional
        newFileName:
        existingArchitecturalSubmissionID:
        architecturalSubmissionAttachmentID:
      }
    })
  */
  async StoreAttachment({}, { params, file }) {
    const { successful, message } = await storeArchAttachment({
      attachment: file,
      params
    })
    if (!successful) {
      console.error(message)
      return
    }
  },

  // Update Document
  async updateDocument({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/Architectural/ArchitecturalSubmissionAttachment`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.architecturalSubmissionAttachmentID > 0) {
        if (payload.remove && payload.reomove != undefined && payload.remove == true) {
          notifyMessage(`Successfully removed the architectural attachment document.`)
        } else {
          notifyMessage(`Successfully updated the architectural attachment document description.`)
        }
        done()
      } else {
        notifyError(
          'There was a problem updating this architectural submission document description.'
        )
      }
    } catch (exception) {
      notifyProblem(
        'There was a problem updating this architectural submission document description.'
      )
      console.error(exception)
    }
  },

  async addDocument({}, { payload }) {
    try {
      const formData = new FormData()
      formData.append('file', payload.file)

      const path = `/Architectural/ArchitecturalSubmissionAttachment/StoreAttachment?newFileName=${payload.newFileName}&existingArchitecturalSubmissionID=${payload.existingArchitecturalSubmissionID}&description=${payload.description}`
      const architecturalSubmissionAttachment = await kms.post(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      if (isDebug == true)
        console.debug('addDocument=' + JSON.stringify(architecturalSubmissionAttachment))

      return {
        returned: architecturalSubmissionAttachment
      }
    } catch (exception) {
      notifyProblem('There was a problem adding the architectural request attachment document.')
      console.error(exception)
    }

    return {
      returned: null
    }
  },

  /*
    await archReqAttachments.dispatch ('Erase', {
      // architecturalSubmissionAttachmentID
      id:
    })
  */
  async Erase({}, { id }) {
    try {
      await kms.delete(`/Architectural/ArchitecturalSubmissionAttachment/${id}`)
    } catch (exception) {
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const archReqAttachments = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})

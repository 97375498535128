/*
  import { archVoteNotes } from '@/services/ArchitecturalVoting/ArchitecturalVoteNote/store'
*/

import Vuex from 'vuex'

import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

const actions = {
  /* eslint-disable no-empty-pattern */

  /*
    await archVoteNotes.dispatch ('put', {
      json: {
        architecturalVoteID: , // Number
        architecturalVoteNoteID: , // Number
        note: ""
      }
    })
  */
  async put({}, { json }) {
    try {
      await kms.put('/ArchitecturalVoting/ArchitecturalVoteNote', json)
    } catch (exception) {
      notifyProblem(exception)
    }
  },

  /*
    await archVoteNotes.dispatch ('erase', {
      architecturalVoteNoteID: // Number
    })
  */
  async erase({}, { architecturalVoteNoteID }) {
    try {
      await kms.delete(
        `/ArchitecturalVoting/ArchitecturalVoteNote/${architecturalVoteNoteID}?forceDelete=true`
      )
    } catch (exception) {
      notifyProblem(exception)
    }
  }

  /* eslint-enable no-empty-pattern */
}
const getters = {}

export const archVoteNotes = new Vuex.Store({
  state: {},
  mutations: {},
  actions,
  getters
})

import { archReqs } from '@/services/Architectural/ArchitecturalSubmission/store'

export function mounted() {
  this.unsubscribeArchReqs = archReqs.subscribe(mutation => {
    const mType = mutation.type

    if (mType === 'listUpdated') {
      this.getList({ load: true })
    }
  })

  this.refresh()
}

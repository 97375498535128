/*
	import { deleteArchAttachment } from '@/services/Architectural/ArchitecturalSubmissionAttachment/Delete';

  const { result, successful, message } = await deleteArchAttachment ({
    id: architecturalSubmissionAttachmentID
  });
  if (!successful) {
    console.error (message);
    return;
  }
*/

import kms from '@/services/kms'

export async function deleteArchAttachment({ id }) {
  let message = 'The architectural request could not be deleted.'

  try {
    const result = await kms.delete(
      `/Architectural/ArchitecturalSubmissionAttachment/${id}?forceDelete=true`
    )

    return {
      successful: true,
      message: '',
      result
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    result: null
  }
}

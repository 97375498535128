/*
  A.K.A. Store the photos of the arch request
*/

/*
  import { storeAttachments } from '@/services/Architectural/ArchitecturalSubmissionAttachment/sequences/storeAttachments';
  
  const { uploads, problems } = await storeAttachments ({
    architecturalSubmissionID,

    // Array of Files
    attachments: []
  });
  if (!successful) {

  }
*/

import { storeArchAttachment } from '../StoreAttachment'
import _get from 'lodash/get'

export async function storeAttachments({ architecturalSubmissionID, attachments }) {
  const results = await Promise.all(
    attachments.map(attachment => {
      const newFileName = _get(attachment, 'name', '')

      return storeArchAttachment({
        params: {
          newFileName,
          existingArchitecturalSubmissionID: architecturalSubmissionID
        },
        attachment
      })
    })
  )

  const problems = []
  const uploads = []
  for (let a = 0; a < results.length; a++) {
    const result = results[a]
    const { successful, message } = result

    if (successful === false) {
      problems.push({ message })
    }

    if (successful === true) {
      uploads.push({})
    }
  }

  return {
    uploads,
    problems
  }
}

/*
	import { getAReqsList } from '@/services/Architectural/ArchitecturalSubmission/List';

  const { list, successful, message } = await getAReqsList ({
    params: {
    	// required
			hoaID

			// optional
			ownerID

      pendingStatus: true,
      pendingNotVotedStatus: true

			approvedStatus: true,
      deniedStatus: true,
    }
  });
  if (!successful) {
    console.error (message);
    return;
  }
*/

import kms from '@/services/kms'

export async function getAReqsList({ params }) {
  let message = 'The architectural requests list could not be retrieved.'

  try {
    const results = await kms.get(`/Architectural/ArchitecturalSubmission/ListBasic`, {
      params
    })

    console.log({
      results
    })

    let list = results.results
    if (!Array.isArray(list)) {
      list = []
    }

    return {
      successful: true,
      message,
      list
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    list: []
  }
}

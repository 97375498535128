import _get from 'lodash/get'
import Button from '@/components/buttons/Button'
import { parseDate } from '@/utilities/Date/parse'
import { deleteArchAttachment } from '@/services/Architectural/ArchitecturalSubmissionAttachment/Delete'
import { getArchAttachmentsList } from '@/services/Architectural/ArchitecturalSubmissionAttachment/List'

export const methods = {
  confirmDeletePhoto(id, description) {
    this.$buefy.dialog.confirm({
      title: 'Delete Architectural Photo',
      message: `Are you sure you want to <b>delete</b> the architectural photo for: "${description ||
        'this photo'}"?`,
      confirmText: 'Delete Architectural Photo',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deletePhoto(id)
    })
  },

  async deletePhoto(id) {
    const { successful, message } = await deleteArchAttachment({
      id: id
    })
    if (!successful) {
      console.debug(message)
      return
    }

    this.loading = false
    this.selectedPhoto = null
    this.reload()
  },

  processPhotoUpdate(photo) {
    this.toggle = true
    this.architecturalSubmissionAttachmentID = photo.architecturalSubmissionAttachmentID
    this.selectedPhoto = photo
  },

  createPhotoModal() {
    this.selectedPhoto = null
    this.toggle = true
  },

  determineRows(list) {
    console.debug('in determinRows for Photo...')
    if (list) {
      var dateFormat = 'LL'

      //All except pdf
      let filteredList = list.filter(
        f =>
          f.mimeType != null &&
          (f.mimeType.indexOf('png') !== -1 ||
            f.mimeType.indexOf('jpeg') !== -1 ||
            f.mimeType.indexOf('jpg') !== -1 ||
            f.mimeType.indexOf('gif') !== -1)
      )

      this.photosList = filteredList.map(i => {
        const tempDate = _get(i, 'createdDate', '')
        const createdDate = parseDate(tempDate, dateFormat)

        const filename = _get(i, 'name', '')
        let description = _get(i, 'description', '')
        var archSubAttachID = _get(i, 'architecturalSubmissionAttachmentID', 0)

        console.debug('filename=' + filename + 'description' + description)

        if (!description || description === null || description === '') {
          description = filename
        }

        console.debug('filename=' + filename + 'description' + description)

        return {
          description,
          createdDate,
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.processPhotoUpdate(i)
              },
              text: 'Edit'
            }
          },
          erase: {
            component: Button,
            props: {
              onClick: () => {
                this.confirmDeletePhoto(archSubAttachID, description)
              },
              text: 'Delete'
            }
          }
        }
      })
    }
  },

  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  async loadPhotoList() {
    await getArchAttachmentsList({
      params: {
        hoaID: this.hoaId,
        architecturalSubmissionID: this.architecturalSubmissionID
      }
    }).then(({ list }) => {
      if (list) {
        this.determineRows(list)
      }
    })
  },

  async reload() {
    this.loading = true

    this.architecturalSubmissionID = this.$route.params.id || 0

    await this.loadPhotoList()

    this.rows = []

    this.loading = false
  }
}

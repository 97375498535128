import _get from 'lodash/get'
import parseAddress from '@/utilities/address/parse'
import { notifyMessage, notifyProblem } from '@/services/notify'
import { archVotes } from '@/services/ArchitecturalVoting/ArchitecturalVote/store'
import { archReqs } from '@/services/Architectural/ArchitecturalSubmission/store'
import { getFile } from '@/services/Architectural/ArchitecturalSubmission/GetFile'
import { getAttachments } from '@/services/Architectural/ArchitecturalSubmissionAttachment/sequences/getAttachments'
import { deleteArchAttachment } from '@/services/Architectural/ArchitecturalSubmissionAttachment/Delete'
import { storeAttachments } from '@/services/Architectural/ArchitecturalSubmissionAttachment/sequences/storeAttachments'
import { storeArchDoc } from '@/services/Architectural/ArchitecturalSubmission/StoreDocument'
import { now } from '@/utilities/Date/now'

function getVoteStatus(vote) {
  const wasApproved = vote.wasApproved
  const voteDate = vote.voteDate

  if (wasApproved) {
    return true
  }

  /*
    that is,
    a vote occurred (on the voteDate)
    and wasApproved was not affected
  */
  if (wasApproved === false && typeof voteDate === 'string') {
    return false
  }

  return null
}

export const methods = {
  /*

  */
  async retrievePhotos() {
    this.loading.photos = true

    const architecturalSubmissionID = this.architecturalSubmissionID
    const hoaID = this.hoaId

    const { results } = await getAttachments({
      architecturalSubmissionID,
      hoaID
    })

    const photos = []
    for (let a = 0; a < results.length; a++) {
      const result = results[a]

      if (_get(result, 'successful', false) === true) {
        photos.push({
          content: _get(result, 'photo', ''),
          id: _get(result, 'id', '')
        })
      }
    }

    this.photos = photos
    this.loading.photos = false
  },
  async retrieveDocument() {
    this.loading.doc = true

    const architecturalSubmissionID = this.architecturalSubmissionID

    if (this.hasDocument == true) {
      const { doc, successful, message } = await getFile({
        params: {
          architecturalSubmissionID,
          asBase64: true
        }
      })
      if (!successful) {
        console.error(message)
        this.loading.doc = false
        return
      }

      if (doc && doc != undefined) {
        this.doc.content = doc
      }
    }

    await new Promise(resolve => {
      setTimeout(() => {
        this.loading.doc = false
        resolve()
      }, 400)
    })
  },

  returnToParent() {
    this.$router.push({
      name: 'architecturalRequests',
      params: {
        loadrequests: true
      }
    })
  },

  async openPDFModal() {
    if (this.editMode) {
      return
    }

    this.$refs.pdfModal.open({
      src: this.doc.content
    })
  },
  async openPDFUploader() {
    this.pdfUploader.active = true
    this.$forceUpdate()
  },
  async updatePDF({ documents }) {
    this.potentialPDFs = documents
  },
  async uploadNewPDF() {
    if (!this.uploadDescription || this.uploadDescription === '') {
      notifyProblem('Please enter an upload Description.')
      this.pdfUploader.loading = false
      return
    }

    const potentialPDFs = this.potentialPDFs

    if (Array.isArray(potentialPDFs) && potentialPDFs.length >= 1) {
      this.pdfUploader.loading = true

      const architecturalSubmissionID = this.architecturalSubmissionID
      const hoaID = this.hoaId
      const description = this.uploadDescription

      const {
        successful: docSaved,
        message: docMessage,
        documentID: documentID
      } = await storeArchDoc({
        doc: potentialPDFs[0],
        params: {
          hoaID,
          architecturalSubmissionID,
          description
        }
      })
      if (!docSaved || documentID <= 0) {
        notifyProblem(docMessage)
      }

      this.hasDocument = true
      this.documentID = documentID

      if (this.isDebug == true) console.debug('uploadNewPDF documentID=' + this.documentID)

      await this.retrieveDocument()

      this.pdfUploader.loading = false
      this.pdfUploader.active = false
      this.$forceUpdate()
    } else {
      notifyProblem('There does not seem to be a PDF selected for upload.')
    }
  },
  async erasePDF() {
    console.log('erasePDF')
  },

  async openPhotoUploader() {
    this.photoUploader.active = true
    this.$forceUpdate()
  },
  async uploadNewPhoto() {
    const photos = this.potentialPhotos
    const hoaID = this.hoaID
    const architecturalSubmissionID = this.architecturalSubmissionID

    if (Array.isArray(photos) && photos.length >= 1) {
      const { problems } = await storeAttachments({
        hoaID,
        architecturalSubmissionID,

        attachments: photos
      })
      if (problems.length >= 1) {
        notifyProblem(`${problems.length} of the photos may not have been uploaded successfully`)
      }

      await this.retrievePhotos()

      this.photoUploader.loading = false
      this.photoUploader.active = false
      this.$forceUpdate()
    }
  },
  async updatePhotos({ items }) {
    this.potentialPhotos = items
  },

  async erasePhoto({ index }) {
    const id = _get(this, ['photos', index, 'id'], null)

    await new Promise(resolve => {
      this.$buefy.dialog.confirm({
        message: `Are you sure you want to delete this photo?`,
        confirmText: 'Yes',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          resolve()
        }
      })
    })

    this.loading.photos = true

    if (typeof id !== 'number' || id < 1) {
      notifyProblem('A problem occurred and the photo could not be deleted')
      this.loading.photos = false
      return
    }

    const { successful, message } = await deleteArchAttachment({
      id
    })
    if (!successful) {
      notifyProblem(message)
      this.loading.photos = false
      return
    }

    this.retrievePhotos()
  },

  async openPhotoModal(index) {
    if (this.editMode === true) {
      return
    }

    this.photoModal = {
      open: true,
      src: this.photos[index].content
    }
  },

  denyRequest() {
    this.saveEdits({
      denied: true
    })
  },
  approveRequest() {
    this.saveEdits({
      approved: true
    })
  },

  async refreshRequestPage({ id }) {
    this.loading.requestSpecifications = true
    this.loading.committee = true
    this.loading.voteDetails = true

    const list = await archVotes.dispatch('getList', {
      params: {
        hoaId: this.hoaId,
        architecturalSubmissionID: id
      }
    })
    if (list.length >= 1) {
      this.parseRequestDetails({
        details: list[0].architecturalSubmission
      })
    } else {
      /*
        if their aren't any voters,
        get the details of the request
        from the archReqs api
      */
      const { result } = await archReqs.dispatch('getDetails', {
        architecturalSubmissionID: id
      })

      this.parseRequestDetails({
        details: result
      })
    }

    this.parseCommitteeDetails({
      list
    })

    this.loading.requestSpecifications = false
    this.loading.committee = false
    this.loading.voteDetails = false
  },

  //
  //  Voting Committee Card
  //
  async updateVotes({ architecturalSubmissionID }) {
    if (
      typeof architecturalSubmissionID !== 'number' &&
      typeof architecturalSubmissionID !== 'string'
    ) {
      notifyProblem('A problem occurred, the committee members votes could not be retrieved.')
      return
    }

    const { list } = await archVotes.dispatch('getList', {
      params: {
        hoaID: this.hoaId,
        architecturalSubmissionID
      }
    })

    this.parseCommitteeDetails({
      list
    })
  },

  //
  // sets 'this.requestDetails'
  parseRequestDetails({ details }) {
    if (_get(details, ['submissionDocumentID'], 0) > 0) {
      this.hasDocument = true
      this.documentID = _get(details, ['submissionDocumentID'], 0)
    }

    const toBeCompletedBy = _get(details, ['toBeCompletedBy'], '')

    let contractor = null
    const _contractor = _get(details, ['contractor'], '')
    if (toBeCompletedBy === 'contractor') {
      if (typeof _contractor === 'string' && _contractor.length >= 1) {
        contractor = _contractor
      }
    }

    this.requestDetails = {
      architecturalSubmissionId: _get(details, ['architecturalSubmissionID'], null),
      ownerId: _get(details, ['owner', 'ownerID'], null),

      ownerName: _get(details, ['owner', 'name'], null),
      address: parseAddress({
        address: _get(details, ['owner', 'unit', 'address'], null)
      }),

      submissionDocumentID: _get(details, ['submissionDocumentID'], 0),

      category: _get(details, ['architecturalCategory', 'name'], ''),
      description: _get(details, ['description'], ''),
      detailedDescription: _get(details, ['detailedDescription'], ''),
      submissionDocumentDescription: _get(details, ['submissionDocumentDescription'], ''),

      feeAmount: _get(details, ['feeAmount'], ''),
      contractor,
      toBeCompletedBy,
      cancelledDate: _get(details, ['cancelledDate'], null),
      wasApproved: _get(details, ['wasApproved'], '?'),
      respondByDate: _get(details, ['respondByDate'], ''),
      estimatedCompletionDate: _get(details, ['estimatedCompletionDate'], ''),
      submissionDate: _get(details, ['submissionDate'], ''),
      votingCompleteDate: _get(details, ['votingCompleteDate'], ''),
      architecturalSubmissionAttachments: _get(details, ['architecturalSubmissionAttachments'], [])
    }
  },

  //
  // sets 'this.committeeMembers'
  parseCommitteeDetails({ list }) {
    this.results = {
      yes: 0,
      no: 0,
      pending: 0
    }
    this.isConcludable = false
    this.isApprovable = false
    this.isDeniable = false

    if (Array.isArray(list)) {
      const committeeMembers = list.map(s => {
        const isAuthenticated = _get(s, ['committeeMember', 'isAuthenticated'], false)

        const decision = getVoteStatus(s)
        if (decision === true) {
          this.results['yes']++
        } else if (decision === null) {
          this.results['pending']++
        } else if (decision === false) {
          this.results['no']++
        }

        let reasonForDenial = _get(s, ['disapproveReason'], '')
        if (typeof reasonForDenial !== 'string') {
          reasonForDenial = ''
        }

        let stipulations = _get(s, ['architecturalStipulations'], [])
        if (!Array.isArray(stipulations)) {
          stipulations = []
        }

        let notes = _get(s, ['architecturalVoteNotes'], [])
        if (!Array.isArray(notes)) {
          notes = []
        }

        return {
          committeeMemberID: _get(s, ['committeeMemberID'], ''),
          architecturalVoteID: _get(s, ['architecturalVoteID'], ''),
          //
          name: _get(s, ['committeeMember', 'name'], ''),
          decision,
          isAuthenticated,
          //
          notes: notes.map(note => {
            let text = _get(note, 'note', '')
            if (typeof text !== 'string') {
              text = ''
            }

            return {
              text,
              id: _get(note, 'architecturalVoteNoteID', null)
            }
          }),
          stipulations: stipulations.map(stipulation => {
            let text = _get(stipulation, 'stipulation', '')
            if (typeof text !== 'string') {
              text = ''
            }

            return {
              text
            }
          }),
          reasonForDenial
        }
      })

      this.committeeMembers = committeeMembers

      const total = committeeMembers.length
      const halfOfTotal = total / 2

      if (this.results.yes > halfOfTotal) {
        this.isConcludable = true
        this.isApprovable = true
      }
      if (this.results.no > halfOfTotal) {
        this.isConcludable = true
        this.isDeniable = true
      }
    }
  },

  //
  //
  openEditMode() {
    if (this.editMode) {
      this.confirmFinishEditingWithoutSaving()
    } else {
      this.editMode = true
    }
  },
  confirmFinishEditingWithoutSaving() {
    this.$buefy.dialog.confirm({
      message: `Are you sure you want to finish editing without saving?`,
      confirmText: 'Yes',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        this.editMode = false
      }
    })
  },

  //
  async saveEdits({ approved = false, denied = false } = {}) {
    if (this.isDebug == true)
      console.debug(
        this.requestDetails.submissionDocumentID + ' saveEdits documentID=' + this.documentID
      )

    this.loading.requestSpecifications = true
    this.loading.committee = true
    this.loading.voteDetails = true

    const specifications = this.$refs.specifications
    const values = specifications.getValues()
    const feeAmount = values.fee.substring(1)

    await archReqs.dispatch('put', {
      json: {
        hoaID: this.hoaId,
        ownerID: this.requestDetails.ownerId,
        architecturalSubmissionID: this.requestDetails.architecturalSubmissionId,
        submissionDocumentID: this.documentID
          ? this.documentID
          : this.requestDetails.submissionDocumentID,
        submissionDocumentDescription: this.uploadDescription ? this.uploadDescription : null,
        architecturalCategoryID: values.category,
        description: values.description,
        detailedDescription: values.detailedDescription,
        feeAmount,
        //
        ...(approved ? { wasApproved: true } : {}),
        ...(denied ? { wasApproved: false } : {}),
        ...(approved === true || denied === true ? { votingCompleteDate: now() } : {}),
        //
        estimatedCompletionDate: values.estimatedCompletionDate,
        submissionDate: values.submissionDate,
        respondByDate: values.respondByDate
      }
    })

    this.editMode = false

    await this.refreshRequestPage({
      id: this.requestDetails.architecturalSubmissionId
    })

    notifyMessage('Architectural request details have been saved.')
  }
}

<template>
  <div class="section">
    <div class="container">
      <div class="is-size-4">Submission Document (optional)</div>
      <div class="box is-8" :style="{ marginTop: '20px' }">
        <div v-if="base64pdf" class="has-text-centered">
          <div v-dragscroll="true" :class="['doc-viewer', { 'zoom-active': scale > 0.9 }]">
            <pdf
              style="display: none;"
              :src="base64pdf"
              @num-pages="pageCount = new Array($event)"
            />
            <!-- Actual document -->
            <pdf
              v-for="(page, index) in pageCount"
              :key="index"
              :src="base64pdf"
              :page="index + 1"
              :scale="scale"
              :resize="true"
              :style="{ margin: 'auto', width: `${100 * scale}%` }"
            />
          </div>

          <div class="doc-controls has-text-center">
            <b-field position="is-centered">
              <div class="control">
                <b-button @click="zoom(-1)">-</b-button>
              </div>
              <div class="control">
                <b-button @click="zoom(1)">+</b-button>
              </div>
            </b-field>
          </div>
        </div>

        <!-- <b-button
          v-if="base64pdf"
          type="is-danger"
          outlined
          :style="{ margin: '0 0 40px' }"
          @click="confirmDeleteDocument()"
        >Delete</b-button> -->

        <div>
          <!-- Show the Upload Form -->
          <b-field class="file">
            <b-upload v-model="file" drag-drop accept="application/pdf">
              <section class="section has-width-500">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="file-pdf" size="is-large"> </b-icon>
                  </p>

                  <p v-if="file">
                    {{ file.name }}
                  </p>
                  <p v-else>
                    Drop a PDF file here, or click to select a file from your computer.
                  </p>
                </div>
              </section>
            </b-upload>
          </b-field>
          <b-button type="is-primary" :disabled="!file" @click="submitFile">Upload2</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import kms from '@/services/kms'
import { mapState } from 'vuex'
import { mapActions } from 'vuex'
import { dragscroll } from 'vue-dragscroll'
import pdf from 'vue-pdf'
import moment from 'moment'
import { notifyError, notifyMessage } from '@/services/notify'

export default {
  name: 'DocumentModal',
  components: {
    pdf
  },

  directives: { dragscroll },

  props: {
    architecturalSubmissionID: Number,
    reloadPhotos: Boolean
  },

  data: function() {
    return {
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      isDebug: false,
      file: null,
      base64pdf: '',
      scale: 1,
      pageCount: []
    }
  },

  mounted() {
    this.loadCurrentPdf()
  },

  computed: {
    ...mapState('architectural', ['uploadedRequestFile']),
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {
    reloadPhotos() {
      if (this.reloadPhotos && this.reloadPhotos != undefined && this.reloadPhotos == true) {
        this.loadCurrentPdf()
      }
    }
  },

  methods: {
    ...mapActions('architectural', ['uploadRequestDocument']),

    async loadCurrentPdf() {
      try {
        const returned = await kms.get(`/Architectural/ArchitecturalSubmission/GetFile`, {
          params: {
            architecturalSubmissionID: this.architecturalSubmissionID,
            asBase64: true
          }
        })

        this.base64pdf = returned
      } catch (e) {
        if (this.isDebug == true) console.debug(e)
      }
    },

    // confirmDeleteDocument () {},

    async submitFile() {
      if (this.architecturalSubmissionID <= 0) {
        notifyError('There was a problem uploading this document.')
        return
      }

      try {
        const payload = {
          file: this.file,
          architecturalSubmissionID: this.architecturalSubmissionID
        }

        const results = this.uploadRequestDocument(payload)
        this.base64pdf = ''
        if (results && results != undefined) {
          if (this.isDebug == true)
            console.debug('results.............................=' + JSON.stringify(results))

          setTimeout(() => this.loadCurrentPdf(), 2000)

          notifyMessage(`Successfully uploaded the document.`)
        } else {
          notifyError('There was a problem uploading this document.')
        }
      } catch (e) {
        notifyError(e)
      }
      this.loading = false
    },

    zoom(direction) {
      const amt = Math.sqrt(2)
      if (direction > 0) {
        this.scale *= amt
      } else {
        this.scale /= amt
      }

      // constrain to min/max
      this.scale = Math.max(0.5, Math.min(4, this.scale))
    }
  }
}
</script>

<style lang="scss">
.modal-h-full {
  left: 50%;
  transform: translate(-50%);
}

#request-image-upload {
  .upload .upload-draggable {
    border: none 0;
    border-radius: 0;
    width: 100%;
    padding: 0;
  }

  .modal-close,
  .delete {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    margin: 5px 5px 0 0;
  }
}

.doc-viewer {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 130%;
  min-height: 40vh;
  max-height: calc(100vh - 320px);
  border: 1px solid #dbdbdb;

  &.zoom-active {
    cursor: grab;
  }
}

.doc-controls {
  margin-top: -1em;
}
</style>

import _get from 'lodash/get'
import kms from '@/services/kms'
import { getOwner } from '@/services/Roster/Owner/getOwner'

export async function getDetails({ architecturalSubmissionID, withDetails = true }) {
  let name = ''

  try {
    const result = await kms.get(
      `/Architectural/ArchitecturalSubmission/${architecturalSubmissionID}`
    )

    const ownerID = _get(result, 'ownerID', null)
    if (withDetails) {
      const { owner, exception } = await getOwner({
        ownerID
      })
      if (exception) {
        console.debug(exception)
      }

      // The (group) name of the property owners
      name = _get(owner, 'name', '')
    }

    return {
      name,
      result
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    name,
    result: null
  }
}

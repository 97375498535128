/*
  import { archReqs } from '@/services/Architectural/ArchitecturalSubmission/store'

  this.unsubscribeArchReqs = archReqs.subscribe((mutation, state) => {
    const mType = mutation.type;
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null);


  })

  this.unsubscribeArchReqs();
*/

import asyncEachOf from 'async/eachOf'
import _get from 'lodash/get'
import Vue from 'vue'
import Vuex from 'vuex'
import { getOwner } from '@/services/Roster/Owner/getOwner'
import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'
import parseAddress from '@/utilities/address/parse'
import { initArchReq } from '@/services/Architectural/ArchitecturalSubmission/CreateAndInitializeVotes'
import { getDetails } from './Get'

async function addDetails({ list }) {
  return await new Promise(resolve => {
    const owners = {}
    for (let a = 0; a < list.length; a++) {
      const ownerID = list[a].ownerID

      if (owners[ownerID] === undefined) {
        owners[ownerID] = []
      }

      owners[ownerID].push(a)
    }

    var exceptions = []
    asyncEachOf(
      owners,
      function(value, key, done) {
        ;(async () => {
          const { owner, exception } = await getOwner({
            ownerID: key
          })

          if (exception) {
            exceptions.push(exception)
            done()
            return
          }

          const name = _get(owner, 'name', '')
          const parsedAddress = parseAddress({
            address: _get(owner, 'mailingAddress', '')
          })

          const archRequestsOfOwner = owners[key]
          for (let a = 0; a < archRequestsOfOwner.length; a++) {
            const archRequestIndex = archRequestsOfOwner[a]

            list[archRequestIndex].name = name
            list[archRequestIndex].address = parsedAddress
          }

          done()
        })()
      },
      function(err) {
        if (err) {
          console.debug(err)
        }

        resolve()
      }
    )
  })
}

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await archReqs.dispatch ('List', {
      params: {
        hoaID,

        ownerID,

        approvedStatus: status == 'Approved' ? true : null,
        pendingStatus: status == 'Pending' ? true : null,
        deniedStatus: status == 'Denied' ? true : null,
        pendingNotVotedStatus: status == 'My Pending' ? true : null
      }
    });
  */
  async List({}, { withDetails = true, params }) {
    var results = {}
    try {
      results = await kms.get('/Architectural/ArchitecturalSubmission/ListBasic', {
        params
      })
    } catch (exception) {
      console.error(
        'The architectural requests list could not be retrieved, perhaps try refreshing the page.'
      )
      notifyProblem(exception)
      return {
        list: []
      }
    }

    const list = results.results
    if (!Array.isArray(list)) {
      console.error(`list not received`, list)
      return {
        list: []
      }
    }

    /*
      adds the name and address of the
      owner that made the request
    */
    if (withDetails) {
      await addDetails({ list })
    }

    return { list }
  },

  /*
    const { result } = await archReqs.dispatch ('getDetails', {
      architecturalSubmissionID:
    });
  */
  async getDetails({}, { architecturalSubmissionID }) {
    return getDetails({ architecturalSubmissionID })
  },

  /*
    await archReqs.dispatch ('put', {
      json: {
        hoaID: 51,
        ownerID: 1782,
        architecturalSubmissionID: 134,

        architecturalCategoryID: 1,
        description: '?',
        detailedDescription: '?',
      }
    });
  */
  async put({}, { json }) {
    try {
      await kms.put('/Architectural/ArchitecturalSubmission', json)
    } catch (exception) {
      console.error(exception)
    }
  },

  // Deny or Approve final vote
  async updateDenyOrApprove({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.post(
        `/ArchitecturalVoting/ArchitecturalVote/FinalizeSubmissionApprovalWithVoteCount`,
        {
          architecturalSubmissionID: payload.architecturalSubmissionID,
          ownerID: payload.ownerID
        }
      )

      if (this.isDebug == true) console.debug('updateDenyOrApprove=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.architecturalSubmissionID > 0) {
        console.debug(`Successfully finalized the voting.`)
        done({ details: results })
      } else {
        notifyProblem('There was a problem finalizing the voting.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem processing the update for this final approval.`)
      console.error(exception)
    }
  },

  /*
    await archReqs.dispatch ('CreateAndInitializeVotes', {
      json: {
        hoaId,
        ownerID,
        //
        architecturalCategoryID,
        feeAmount,
        description,
        detailedDescription,
        //
        submissionDate: _now,
        estimatedCompletionDate,
        respondByDate: _now
      }
    });
  */
  async CreateAndInitializeVotes({ commit }, { json }) {
    console.log('CreateAndInitializeVotes')

    const { result, successful, message } = await initArchReq({
      json
    })

    if (successful) {
      commit('listUpdated', {
        hoaId: json.hoaId
      })
    }

    return {
      result,
      successful,
      message
    }
  }
}
/* eslint-enable no-empty-pattern */

const getters = {}

Vue.use(Vuex)
export const archReqs = new Vuex.Store({
  state: {},
  mutations: {
    async listUpdated() {}
  },
  actions,
  getters
})

import { mapGetters } from 'vuex'

export const computed = {
  ...mapGetters('user', ['currentHoaId', 'currentUnitId', 'currentOwnerId']),
  filteredDescriptionArray() {
    const description = this.description

    const results = [].filter(entry => {
      return entry.indexOf(description) >= 0
    })

    return results
  }
}

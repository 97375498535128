var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"uploader",staticClass:"PDFUploader",attrs:{"pdf-uploader":""}},[_c('b-upload',{attrs:{"multiple":_vm.multiple,"drag-drop":"","expanded":"","accept":"application/pdf"},model:{value:(_vm.attachments),callback:function ($$v) {_vm.attachments=$$v},expression:"attachments"}},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-centered"},[_c('p',[_c('b-icon',{attrs:{"icon":"upload","size":"is-large"}})],1),_vm._t("default"),(_vm.multiple)?_c('p',[_vm._v("Drop the PDFs here or click to choose.")]):_vm._e(),(!_vm.multiple)?_c('div',[(Array.isArray(_vm.pdfs) && _vm.pdfs.length === 1)?_c('p',[_vm._v(" To replace the selected PDF, Drop another PDF here or click to choose. ")]):_c('p',[_vm._v("Drop a PDF here or click to choose.")])]):_vm._e()],2)])]),(_vm.multiple)?_c('div',{style:({ padding: '8px' })},[_vm._v(" "+_vm._s(_vm.attachments.length)+" PDF's selected for upload ")]):_vm._e(),_c('div',{style:({ position: 'relative', display: 'flex', flexWrap: 'wrap', width: '100%' })},[_vm._l((_vm.pdfs),function(pdf,index){return _c('div',_vm._g({key:index,style:(Object.assign({}, (typeof _vm.pdfOnClick === 'function' ? { cursor: 'pointer' } : {}),
        {position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: '200px',
        height: '200px',
        overflow: 'hidden',
        background: _vm.palette.tile,
        borderRadius: '4px',
        margin: '10px',
        boxShadow: ("0 0 4px 1px " + (_vm.palette.tileShadow))}))},
        typeof _vm.pdfOnClick === 'function'
          ? {
              click: function () {
                _vm.pdfOnClick({ index: index, pdf: pdf })
              }
            }
          : {}
      ),[_c('PDF',{staticStyle:{"display":"none"},attrs:{"src":_vm.base64pdf},on:{"num-pages":function($event){_vm.pageCount = new Array($event)}}}),_vm._l((_vm.pageCount),function(page,index){return _c('PDF',{key:index,ref:'pdf-' + index,refInFor:true,style:({
          margin: 'auto',
          width: ((100 * 1) + "%"),
          opacity: _vm.loading ? 0 : 1,
          transition: _vm.transition ? '.2s opacity' : ''
        }),attrs:{"src":pdf,"scale":1,"resize":true,"page":index + 1}})}),_c('button',{staticClass:"delete is-medium",style:({
          position: 'absolute',
          top: '10px',
          right: '10px'
        }),attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteAttachment(index)}}})],2)}),_c('b-loading',{attrs:{"is-full-page":false},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="section" final-stipulation-modal>
    <div class="container">
      <div class="is-size-4">Add Stipulation</div>
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="columns">
                  <div class="column is-12">
                    <valid-input
                      style="width:50px !important;"
                      name="Stipulation"
                      label="Stipulation"
                      type="textarea"
                      vid="Stipulation"
                      placeholder="Stipulation"
                      spellcheck="true"
                      aria-label="Stipulation"
                      class="is-small"
                      rules="required|max:8000"
                      v-model="formData.stipulation"
                    />
                  </div>
                </div>
                <div>
                  <button type="submit" class="button is-primary is-rounded">
                    Save
                  </button>
                  <span class="pl-3">&nbsp;</span>
                  <button
                    @click.prevent="closeModal()"
                    type="button"
                    class="button is-primary is-rounded"
                  >
                    Cancel
                  </button>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'

// components
import ValidInput from '@/components/inputs/ValidInput'

export default {
  props: {
    originalStipulation: Object,
    architecturalSubmission: Object
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    ValidInput
  },

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>

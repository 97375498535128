<template>
  <div class="PDFUploader" ref="uploader" pdf-uploader>
    <b-upload
      v-model="attachments"
      :multiple="multiple"
      drag-drop
      expanded
      accept="application/pdf"
    >
      <section class="section">
        <div class="content has-text-centered">
          <p>
            <b-icon icon="upload" size="is-large" />
          </p>

          <slot></slot>

          <p v-if="multiple">Drop the PDFs here or click to choose.</p>

          <div v-if="!multiple">
            <p v-if="Array.isArray(pdfs) && pdfs.length === 1">
              To replace the selected PDF, Drop another PDF here or click to choose.
            </p>
            <p v-else>Drop a PDF here or click to choose.</p>
          </div>
        </div>
      </section>
    </b-upload>

    <div v-if="multiple" :style="{ padding: '8px' }">
      {{ attachments.length }} PDF's selected for upload
    </div>

    <div :style="{ position: 'relative', display: 'flex', flexWrap: 'wrap', width: '100%' }">
      <div
        v-for="(pdf, index) in pdfs"
        :key="index"
        :style="{
          ...(typeof pdfOnClick === 'function' ? { cursor: 'pointer' } : {}),
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          width: '200px',
          height: '200px',
          overflow: 'hidden',
          background: palette.tile,
          borderRadius: '4px',
          margin: '10px',
          boxShadow: `0 0 4px 1px ${palette.tileShadow}`
        }"
        v-on="
          typeof pdfOnClick === 'function'
            ? {
                click: () => {
                  pdfOnClick({ index, pdf })
                }
              }
            : {}
        "
      >
        <PDF style="display: none;" :src="base64pdf" @num-pages="pageCount = new Array($event)" />
        <!-- Actual document -->
        <PDF
          v-for="(page, index) in pageCount"
          :key="index"
          :ref="'pdf-' + index"
          :src="pdf"
          :scale="1"
          :resize="true"
          :page="index + 1"
          :style="{
            margin: 'auto',
            width: `${100 * 1}%`,
            opacity: loading ? 0 : 1,
            transition: transition ? '.2s opacity' : ''
          }"
        />

        <button
          class="delete is-medium"
          :style="{
            position: 'absolute',
            top: '10px',
            right: '10px'
          }"
          type="button"
          @click="deleteAttachment(index)"
        />
      </div>

      <b-loading :is-full-page="false" v-model="loading" />
    </div>
  </div>
</template>

<style lang="scss">
.PDFUploader {
  .upload-draggable {
    box-shadow: 0 0 3px 1px #e8e5e5;
  }
}
</style>

<script>
/*
  import PDFUploader from '@/components/Fields/PDFUploader'

  documentsUpdated ({ documents }) {
    this.documents = documents;
  },

	<PDFUploader 
		update="documentsUpdated"
	/>
*/

/*
  Notes:
    Loading is glitchy with more than 10 PDF's...
*/

import _get from 'lodash/get'
import PDF from 'vue-pdf'

export default {
  components: {
    PDF
  },
  props: {
    update: {
      type: Function,
      default() {}
    },
    updateV2: {
      type: Function,
      default() {}
    },
    multiple: {
      type: Boolean,
      default: true
    },
    pdfOnClick: {
      type: [Boolean, Function],
      default: false
    }
  },
  data() {
    return {
      palette: {
        tile: `#FFF`,
        tileShadow: `#E6E6E6`
      },
      attachments: [],
      previews: [],
      pdfs: [],
      loading: false,
      transition: true,
      uploadDescription: '',
      pageCount: []
    }
  },
  methods: {
    async deleteAttachment(index) {
      await new Promise(resolve => {
        this.loading = true

        setTimeout(() => {
          resolve()
        }, 200)
      })

      if (this.multiple) {
        this.attachments.splice(index, 1)
      } else {
        this.attachments = []
      }

      this.pdfs.splice(index, 1)

      this.previews.splice(index, 1)
    }
  },
  watch: {
    async attachments() {
      console.log('attachments...', {
        attachments: this.attachments
      })

      let attachments = []
      if (this.multiple) {
        if (!Array.isArray(this.attachments) || this.attachments.length === 0) {
          console.info('no attachments')
          this.updateV2({
            documents: []
          })
          return
        }

        attachments = this.attachments
      } else {
        if (Array.isArray(this.attachments) && this.attachments.length === 0) {
          console.info('no attachments, attachment was deleted')
          this.updateV2({
            documents: []
          })
          return
        }

        attachments = [this.attachments]
      }

      if (this.loading === false) {
        await new Promise(resolve => {
          this.loading = true

          setTimeout(() => {
            resolve()
          }, 200)
        })
      }

      this.update({
        documents: attachments
      })

      for (let a = 0; a < attachments.length; a++) {
        const attachment = attachments[a]
        const docType = _get(attachment, 'type', '')

        if (docType === 'application/pdf') {
          const fileReader = new FileReader()
          fileReader.onload = e => {
            // const result = new Uint8Array(e.target.result);
            // const pdf = _get (this, [ 'pdfs', a ], null);

            this.pdfs[a] = new Uint8Array(e.target.result)
            this.$forceUpdate()
          }

          fileReader.readAsArrayBuffer(attachment)
        } else {
          this.pdfs[a] = null
        }
      }

      setTimeout(() => {
        this.loading = false
      }, 500)

      this.previews = attachments.map(file => {
        return URL.createObjectURL(file)
      })

      this.updateV2({
        documents: attachments
      })
    }
  }
}
</script>

import { mapState } from 'vuex'

import _cloneDeep from 'lodash/cloneDeep'

export const computed = {
  ...mapState({
    hoaId: state => state.user.selectedHoaId
  }),

  committeeMembersApproved() {
    const voteDetailsCheckboxes = this.voteDetailsCheckboxes

    return _cloneDeep(this.committeeMembers).filter(member => {
      if (
        voteDetailsCheckboxes.includes('filterVotesWithoutStipulations') &&
        member.stipulations.length === 0
      ) {
        return false
      }

      return member.decision === true
    })
  },

  conclusionLabel() {
    if (typeof this.requestDetails.wasApproved === 'boolean') {
      const status = this.requestDetails.wasApproved ? 'approved' : 'denied'

      return `This request has already been ${status}.`
    }

    return 'The request can only be approved or denied once a majority has been reached.'
  },

  committeeMembersDenied() {
    return _cloneDeep(this.committeeMembers).filter(member => {
      return member.decision === false
    })
  },

  committeeMembersVoteDetails() {
    return this.committeeMembers
  },

  cardcolumns1: function() {
    let columns = []
    let count = 0

    console.debug('loading denial reason columns....' + JSON.stringify(this.committeeMembers))

    Array.from(this.denialList).forEach(element => {
      if (element && element != undefined) {
        if (element && element != undefined && element.text) {
          count += element.text.length
        }
      }
    })

    let objectKeys = Object.keys(this.denialList)
    if (objectKeys && objectKeys != undefined) {
      let totalLength = objectKeys.length + count

      const multiplier = 1

      totalLength = totalLength * multiplier

      let mid = Math.ceil(totalLength / this.cols)

      for (let col = 0; col < this.cols; col++) {
        columns.push(
          Object.entries(this.denialList)
            .slice(col * mid, col * mid + mid)
            .map(entry => entry[1])
        )
      }
    }

    return columns
  },

  cardcolumns: function() {
    let columns = []
    let count = 0

    console.debug('loading stipulation columns....' + JSON.stringify(this.committeeMembers))

    Array.from(this.stipulationList).forEach(element => {
      if (element && element != undefined) {
        if (element && element != undefined && element.text) {
          count += element.text.length
        }
      }
    })

    let objectKeys = Object.keys(this.stipulationList)
    if (objectKeys && objectKeys != undefined) {
      let totalLength = objectKeys.length + count

      const multiplier = 1

      totalLength = totalLength * multiplier

      let mid = Math.ceil(totalLength / this.cols)

      for (let col = 0; col < this.cols; col++) {
        columns.push(
          Object.entries(this.stipulationList)
            .slice(col * mid, col * mid + mid)
            .map(entry => entry[1])
        )
      }
    }

    return columns
  }
}

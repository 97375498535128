/*
  import { stringContains } from '@/utilities/String/contains'

  stringContains('STring', 'str', { ignoreCase: true }) //-> true
*/

export const stringContains = (str, partial, { ignoreCase = false } = {}) => {
  try {
    if (ignoreCase) {
      if (str.toLowerCase().indexOf(partial.toLowerCase()) >= 0) {
        return true
      }
    } else {
      if (str.indexOf(partial) >= 0) {
        return true
      }
    }
  } catch (exception) {
    return false
  }

  return false
}

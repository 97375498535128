/*
  A.K.A. Get the photos of the arch request
*/

/*
  import { getAttachments } from '@/services/Architectural/ArchitecturalSubmissionAttachment/sequences/getAttachments';
  
  const { results } = await getAttachments ({
    architecturalSubmissionID,
    hoaID
  });
  if (problems.length >= 1) {

  }
*/

import _get from 'lodash/get'

import { getArchAttachmentsList } from '../List'
import { getArchAttachment } from '../GetAttachmentFile'

export async function getAttachments({ architecturalSubmissionID, hoaID }) {
  const { list, successful, message } = await getArchAttachmentsList({
    params: {
      hoaID,
      architecturalSubmissionID
    }
  })
  if (!successful) {
    console.error(message)
    return
  }

  const results = await Promise.all(
    list.map(entry => {
      return getArchAttachment({
        params: {
          architecturalSubmissionAttachmentID: _get(
            entry,
            'architecturalSubmissionAttachmentID',
            ''
          ),
          asBase64: true
        }
      })
    })
  )

  return {
    results
  }
}

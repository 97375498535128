import moment from 'moment'

export function data() {
  return {
    formData: {
      stipulation: ''
    },
    isDebug: true,
    isOpen: false,
    loading: true,
    isFetching: false,
    openOnFocus: true,
    formComplete: false,
    formatDateFn: function(value) {
      return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
    },
    selectedRow: null,
    buttonText: 'Add'
  }
}

/*
	import { now } from '@/utilities/Date/now'
*/

/*
	relevant:
		moment ().add('7','days').utcOffset(0, true).toISOString ()
*/

export function now() {
  return new Date().toISOString()
}

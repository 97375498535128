<template>
  <div>
    <Modal
      adder-modal
      :toggle.sync="showing"
      :loading="loading"
      :styles="{ modalContent: { height: '900px' } }"
    >
      <template v-slot:header>Architectural Request</template>

      <template v-slot:footer>
        <div
          :style="{
            display: 'flex',
            width: '100%',

            justifyContent: 'space-between',
            alignContent: 'flex-end',

            alignItems: 'center'
          }"
        >
          <b-tooltip :active="prevToolTip.length >= 1" :label="prevToolTip" position="is-right">
            <b-button
              icon-left="chevron-left"
              size="is-medium"
              :style="{ marginRight: '8px' }"
              :disabled="prevPrevented"
              @click="prevStep"
              >Previous</b-button
            >
          </b-tooltip>

          <div :style="{ display: 'flex' }">
            <b-tooltip :active="nextToolTip.length >= 1" :label="nextToolTip" position="is-left">
              <b-button
                icon-right="chevron-right"
                size="is-medium"
                @click="nextStep"
                :disabled="nextPrevented"
                >Next</b-button
              >
            </b-tooltip>

            <b-tooltip
              label="To send the request, please complete the form."
              :active="!isLastStep"
              position="is-left"
            >
              <b-button
                @click="sendRequest"
                type="is-primary"
                :disabled="!isLastStep"
                :style="{
                  display: 'block',
                  float: 'right',
                  height: '100%',
                  marginLeft: '8px',
                  padding: '0 30px'
                }"
                >Send</b-button
              >
            </b-tooltip>
          </div>
        </div>
      </template>

      <b-steps v-model="step" :has-navigation="false" label-position="bottom" size="is-small">
        <b-step-item label="Details">
          <div v-if="mode === 'manager'">
            <label class="label">Owner</label>
            <b-autocomplete
              owner-selector
              v-model="ownerFilterQuery"
              :data="filteredOwnersList"
              :open-on-focus="true"
              :loading="isFetching"
              field="label"
              icon="magnify"
              placeholder="First Name, Last Name, or Address"
              required="true"
              @typing="filterOwners"
              @select="option => (selected = option)"
            />
            <br />
          </div>

          <valid-select
            arch-category
            v-model="formData.architecturalCategoryID"
            label="Category"
            placeholder="Category"
            vid="architecturalCategoryID"
            rules="required"
          >
            <option
              v-for="option in categories"
              :value="option.architecturalCategoryID"
              :key="option.architecturalCategoryID"
              >{{ option.name }}</option
            >
          </valid-select>

          <br />

          <b-field label="Work to be Done or Purpose of Request">
            <b-autocomplete
              description-textarea
              v-model="formData.description"
              :data="filteredDescriptionArray"
              :keep-first="false"
              :open-on-focus="true"
              @select="option => (selected = option)"
            >
            </b-autocomplete>
          </b-field>

          <b-field label="Work To Be Completed By">
            <div>
              <b-select
                to-be-completed-by
                placeholder="Select a name"
                v-model="formData.toBeCompletedBy"
              >
                <option v-for="option in toBeCompletedByOptions" :value="option" :key="option">
                  {{ option }}
                </option>
              </b-select>
            </div>
            <div>
              <b-input
                name-of-contract
                :style="{ marginLeft: '10px' }"
                v-if="formData.toBeCompletedBy === 'contractor'"
                v-model="formData.contractor"
                placeholder="name of contractor"
              ></b-input>
            </div>
          </b-field>

          <br />

          <valid-input
            fee-amount
            v-if="mode === 'manager'"
            v-model="formData.feeAmount"
            fee-input
            label="Fee Amount"
            name="FeeAmount"
            type="text"
            vid="feeAmount"
            placeholder="Fee Amount"
          />

          <br />

          <valid-input
            v-model="formData.detailedDescription"
            details-textarea
            label="Detailed Description"
            name="DetailedDescription"
            maxlength="8000"
            type="textarea"
            vid="detailedDescription"
            spellcheck="true"
            rules="required|max:8000"
          />
          <!-- placeholder="Detailed Description" -->

          <br />

          <DatePicker
            v-if="mode === 'manager'"
            submission-date
            v-model="formData.submissionDate"
            :errorMessage.sync="formErrors.submissionDate"
            ariaLabel="Submission Date"
            label="Submission Date"
            :style="{
              paddingRight: '20px',
              paddingBottom: '20px',
              maxWidth: '300px'
            }"
          />

          <DatePicker
            estimated-completion-date
            v-model="formData.estimatedCompletionDate"
            :errorMessage.sync="formErrors.estimatedCompletionDate"
            ref="estCompDate"
            ariaLabel="Estimated Completion Date"
            label="Estimated Completion Date"
            :style="{ paddingRight: '10px' }"
          />

          <DatePicker
            respond-by-date
            v-if="mode === 'manager'"
            v-model="formData.respondByDate"
            :errorMessage.sync="formErrors.respondByDate"
            ariaLabel="Respond By Date"
            label="Respond By Date"
            :style="{ paddingBottom: '20px', maxWidth: '300px' }"
          />

          <br />
          <br />
        </b-step-item>

        <b-step-item label="Add Document">
          <PDFUploader :update="updatePDF" :multiple="false" />
        </b-step-item>

        <b-step-item label="Add Photos">
          <PhotoUploader :update="updatePhotos" />
        </b-step-item>

        <b-step-item label="Disclaimer">
          <div :style="{ padding: '50px 180px', whiteSpace: 'pre-wrap', textAlign: 'center' }">
            {{ disclaimer }}
          </div>
        </b-step-item>
      </b-steps>
    </Modal>
  </div>
</template>

<script>
/*
  <Adder ref="adder" />

  this.$refs.adder.show()
*/

//
import Modal from '@/components/Modal'
import ValidSelect from '@/components/inputs/ValidSelect'
import ValidInput from '@/components/inputs/ValidInput'

import PDFUploader from '@/components/Fields/PDFUploader'

import PhotoUploader from '@/components/Fields/PhotoUploader'
import DatePicker from '@/components/inputs/DatePicker'
//
import { computed } from './keys/computed'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import { watch } from './keys/watch'
//
export default {
  props: {
    added: Function
  },
  components: {
    DatePicker,
    PDFUploader,
    PhotoUploader,
    Modal,
    ValidInput,
    ValidSelect
  },
  computed,
  data,
  methods,
  mounted,
  watch
}
</script>

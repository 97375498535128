import { mapState } from 'vuex'

import _cloneDeep from 'lodash/cloneDeep'

export const computed = {
  ...mapState({
    hoaId: state => state.user.selectedHoaId
  }),

  committeeMembersApproved() {
    const voteDetailsCheckboxes = this.voteDetailsCheckboxes

    return _cloneDeep(this.committeeMembers).filter(member => {
      if (
        voteDetailsCheckboxes.includes('filterVotesWithoutStipulations') &&
        member.stipulations.length === 0
      ) {
        return false
      }

      return member.decision === true
    })
  },

  conclusionLabel() {
    if (typeof this.requestDetails.wasApproved === 'boolean') {
      const status = this.requestDetails.wasApproved ? 'approved' : 'denied'

      return `This request has already been ${status}.`
    }

    return 'The request can only be approved or denied once a majority has been reached.'
  },

  committeeMembersDenied() {
    return _cloneDeep(this.committeeMembers).filter(member => {
      return member.decision === false
    })
  },

  committeeMembersVoteDetails() {
    return this.committeeMembers
  }
}

/*
  example:
    import { getOwner } from '@/services/Roster/Owner/getOwner';

    const { owner, exception } = await getOwner ({
      ownerID
    });
*/
//
import kms from '@/services/kms'
//
export async function getOwner({ ownerID } = {}) {
  var result = {}

  try {
    result = await kms.get(`/Roster/Owner/${ownerID}`)
  } catch (exception) {
    console.debug(exception)

    return {
      owner: {},
      exception
    }
  }

  return {
    owner: result,
    exception: null
  }
}

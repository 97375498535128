/*
  import { archSettingsStore } from '@/services/Architectural/ArchitecturalSetting/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await archSettingsStore.dispatch ('List', {
      hoaId
    });
  */
  async getArchitecturalSettingById({}, { hoaID }) {
    try {
      console.debug('in getArchitecturalSettingById...')
      const params = {
        hoaID
      }

      const result = await kms.get(`/Architectural/ArchitecturalSetting/GetByHoaId`, {
        params
      })

      console.debug('getArchitecturalSettingById=' + JSON.stringify(result))

      return {
        result: result
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving this architectural setting.`)
      console.error(exception)
    }

    return {
      result: {}
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const archSettingsStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})

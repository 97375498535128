<template>
  <div
    :style="{
      display: 'flex',
      /* borderBottom: '1px solid #f4f2f2', */
      padding: '3px',
      alignItems: 'center'
    }"
  >
    <div
      :style="{
        display: 'inline-block',
        marginRight: '12px',
        flexBasis: '200px',
        flexGrow: 0,
        flexShrink: 0
      }"
    >
      <b-tag :style="{ fontWeight: 'bold' }">{{ label }}</b-tag>
    </div>

    <slot />
  </div>
</template>

<script>
/*
  Guide:

    <SpecificationPanel :label="label">

    </SpecificationPanel />
*/

export default {
  props: {
    label: String
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContent',{attrs:{"title":_vm.$t('architecturalRequests.title')}},[_c('b-button',{style:({ marginBottom: '20px' }),attrs:{"make-request":"","type":"is-primary","rounded":""},on:{"click":_vm.openAdder}},[_vm._v("Submit a New Request")]),_c('ModernTable',{attrs:{"owner-arch-quests":"","onUpdate":_vm.updateTable,"columns":[
      {
        field: 'id',
        label: 'Id',
        sortable: true,
        searchable: true,
        width: '7%'
      },
      {
        field: 'category',
        label: 'Category',
        sortable: true,
        searchable: true,
        width: '20%'
      },
      {
        field: 'description',
        label: 'Description',
        sortable: true,
        searchable: true,
        width: '20%'
      },
      {
        field: 'date',
        label: 'Date Submitted',
        date: true,
        sortable: true,
        searchable: true,
        width: '20%'
      },
      {
        field: 'status',
        label: 'Status',
        width: '15%',
        sortable: false,
        selected: 'Pending',
        searchable: [
          {
            label: 'All',
            filter: ''
          },
          {
            label: 'Approved',
            filter: 'Approved'
          },
          {
            label: 'Pending',
            filter: 'Pending'
          },
          {
            label: 'Denied',
            filter: 'Denied'
          }
        ]
      },
      {
        field: 'details',
        width: '5%'
      }
    ],"rows":this.table.rows,"filters":{
      show: true
    },"loading":_vm.table.loading}}),_c('Adder',{ref:"adder"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
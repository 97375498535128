/*
  import { archStipulations } from '@/services/ArchitecturalVoting/ArchitecturalStipulation/store'

  const { list } = archStipulations.dispatch ('getList') ({
    params: {
      hoaID,

      // optional
      architecturalSubmissionID,
      architecturalVoteID,
    }
  });
*/

/*
  possible structure {
    [hoaId]: {
      [architecturalSubmissionID]: {
        [architecturalVoteID]: {

        }
      }
    }
  }

  // committee member id maps to vote id??
  hoa -> arch request -> vote
*/

import Vuex from 'vuex'

import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

import { addOne } from './actions/addOne'
import { deleteOne } from './actions/deleteOne'

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await archStipulations.dispatch ('getList')
  */
  async getList({}, { params }) {
    try {
      const { results } = await kms.get('/ArchitecturalVoting/ArchitecturalStipulation/List', {
        params
      })

      return {
        list: results
      }
    } catch (exception) {
      notifyProblem(`Architectural stipulation list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  /*
    await archStipulations.dispatch ('deleteAndAddMultiple', {
      toAdd: [ #, #, # ],

      // array of integers
      toRemove: [ ]
    })
  */
  async deleteAndAddMultiple({ dispatch }, { toAdd, toRemove }) {
    await Promise.all([
      ...toAdd.map(details => {
        return dispatch('addOne', details)
      }),
      ...toRemove.map(id => {
        return dispatch('deleteOne', {
          architecturalStipulationId: id
        })
      })
    ])
  },

  /*
    await archStipulations.dispatch ('deleteMultiple', {
      // array of integers
      list: [ #, #, # ]
    })
  */
  async deleteMultiple({}, { list }) {
    return await Promise.all(
      list.map(id => {
        return archStipulations.dispatch('deleteOne', {
          architecturalStipulationId: id
        })
      })
    )
  },

  deleteOne,
  addOne
}
/* eslint-enable no-empty-pattern */

const getters = {}

export const archStipulations = new Vuex.Store({
  state: {},
  mutations: {},
  actions,
  getters
})

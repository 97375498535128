import _get from 'lodash/get'
import Button from '@/components/buttons/Button'
import { parseDate } from '@/utilities/Date/parse'
import { deleteArchAttachment } from '@/services/Architectural/ArchitecturalSubmissionAttachment/Delete'
import { getArchAttachmentsList } from '@/services/Architectural/ArchitecturalSubmissionAttachment/List'

export const methods = {
  confirmDeleteDocument(id, description) {
    this.$buefy.dialog.confirm({
      title: 'Delete Architectural Document',
      message: `Are you sure you want to <b>delete</b> the architectural document for: "${description}"?`,
      confirmText: 'Delete Architectural Document',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteDocument(id)
    })
  },

  async deleteDocument(id) {
    const { successful, message } = await deleteArchAttachment({
      id: id
    })
    if (!successful) {
      console.debug(message)
      return
    }

    this.loading = false
    this.selectedDocument = null
    this.reload()
  },

  processDocument(document) {
    this.documentToggle = true
    this.selectedDocument = document
  },

  processDocumentUpdate(document) {
    this.toggle = true
    this.architecturalSubmissionAttachmentID = document.architecturalSubmissionAttachmentID
    this.selectedDocument = document
  },

  createDocumentModal() {
    this.selectedDocument = null
    this.toggle = true
  },

  determineRows(list) {
    if (list) {
      var dateFormat = 'LL'

      let filteredList = list.filter(f => f.mimeType != null && f.mimeType.indexOf('pdf') !== -1)

      this.documentsList = filteredList.map(i => {
        const tempDate = _get(i, 'createdDate', '')
        const createdDate = parseDate(tempDate, dateFormat)

        var description = _get(i, 'description', '')
        var archSubAttachID = _get(i, 'architecturalSubmissionAttachmentID', 0)

        return {
          description: description,
          createdDate: createdDate,
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.processDocumentUpdate(i)
              },
              text: 'Edit'
            }
          },
          erase: {
            component: Button,
            props: {
              onClick: () => {
                this.confirmDeleteDocument(archSubAttachID, description)
              },
              text: 'Delete'
            }
          }
        }
      })
    }
  },

  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  async loadDocumentList() {
    await getArchAttachmentsList({
      params: {
        hoaID: this.hoaId,
        architecturalSubmissionID: this.architecturalSubmissionID
      }
    }).then(({ list }) => {
      if (list) {
        this.determineRows(list)
      }
    })
  },

  async reload() {
    this.loading = true

    this.architecturalSubmissionID = this.$route.params.id || 0

    await this.loadDocumentList()

    this.rows = []

    this.loading = false
  }
}

<template>
  <PageContent :title="$t('architecturalRequests.title')">
    <b-button
      make-request
      type="is-primary"
      rounded
      @click="openAdder"
      :style="{ marginBottom: '20px' }"
      >Submit a New Request</b-button
    >

    <ModernTable
      owner-arch-quests
      :onUpdate="updateTable"
      :columns="[
        {
          field: 'id',
          label: 'Id',
          sortable: true,
          searchable: true,
          width: '7%'
        },
        {
          field: 'category',
          label: 'Category',
          sortable: true,
          searchable: true,
          width: '20%'
        },
        {
          field: 'description',
          label: 'Description',
          sortable: true,
          searchable: true,
          width: '20%'
        },
        {
          field: 'date',
          label: 'Date Submitted',
          date: true,
          sortable: true,
          searchable: true,
          width: '20%'
        },
        {
          field: 'status',
          label: 'Status',
          width: '15%',
          sortable: false,
          selected: 'Pending',
          searchable: [
            {
              label: 'All',
              filter: ''
            },
            {
              label: 'Approved',
              filter: 'Approved'
            },
            {
              label: 'Pending',
              filter: 'Pending'
            },
            {
              label: 'Denied',
              filter: 'Denied'
            }
          ]
        },
        {
          field: 'details',
          width: '5%'
        }
      ]"
      :rows="this.table.rows"
      :filters="{
        show: true
      }"
      :loading="table.loading"
    />

    <Adder ref="adder" />
  </PageContent>
</template>

<script>
import PageContent from '@/containers/PageContent'
//
import ModernTable from '@/components/tables/Modern/Table'
//
import { data } from './keys/data'
import { computed } from './keys/computed'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import { beforeDestroy } from './keys/beforeDestroy'
import { watch } from './keys/watch'
//
import Adder from '@/pages/Shared/architecturalRequest/Adder'
//
export default {
  components: {
    Adder,
    ModernTable,
    PageContent
  },
  beforeDestroy,
  data,
  computed,
  methods,
  mounted,
  watch,
  i18n: {
    messages: {
      en: {
        architecturalRequests: {
          title: 'Architectural Requests'
        }
      }
    }
  }
}
</script>

<template>
  <SpecificationPanel :label="label">
    <span v-if="!editMode" :style="Object.assign({}, { display: 'inline-block' }, textStyles)">{{
      text
    }}</span>

    <b-input
      :aria-label="label"
      v-model="inputText"
      v-if="editMode"
      :style="Object.assign({}, { display: 'inline-block', flexGrow: 1 }, textStyles)"
      type="textarea"
      maxlength="8000"
    >
    </b-input>
  </SpecificationPanel>
</template>

<script>
import SpecificationPanel from './SpecificationPanel'

export default {
  components: {
    SpecificationPanel
  },

  props: {
    label: String,
    labelStyles: {
      type: Object,
      default: () => {
        return {}
      }
    },
    text: [String, Number],
    textStyles: {
      type: Object,
      default: () => {
        return {}
      }
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      inputText: this.text
    }
  },

  watch: {
    text(value) {
      this.inputText = value
    }
  },

  methods: {
    getValue() {
      return this.inputText
    }
  }
}
</script>

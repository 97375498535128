<template>
  <PageContentLayoutOnly>
    <ModernTable :filters="filters" :rows="photosList" :columns="columns" :pagination="pagination">
      <template v-slot:header>
        <div class="pb-5">
          <b-button @click="createPhotoModal()" size="is-small" type="is-primary" rounded>
            Attach Photo
          </b-button>
          <b-button
            type="is-ghost"
            @click="toggleFilters"
            :style="styles.filterButton"
            :class="'filter-button ' + (filters.show ? 'active' : '')"
          >
            <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
            </b-icon>
            <div style="display: inline-block">Filter</div>
          </b-button>
        </div>
      </template>
    </ModernTable>
    <Modal :toggle.sync="toggle">
      <ImageModal
        :architecturalSubmissionID="parseInt(architecturalSubmissionID)"
        :architecturalSubmissionAttachment="selectedPhoto"
        :onlyPhotos="true"
      />
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import ImageModal from '../documents/components/DocumentModal'
import Modal from '@/components/Modal'

import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'ArchitecturalImages',

  components: {
    PageContentLayoutOnly,
    ModernTable,
    ImageModal,
    Modal
  },

  data,
  methods,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { uploads: { title: 'Photos' } }
    }
  }
}
</script>

<style></style>

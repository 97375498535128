<template>
  <SpecificationPanel :label="label">
    <span v-if="!editMode" :style="Object.assign({}, { display: 'inline-block' }, textStyles)">{{
      text
    }}</span>
    <input
      v-if="editMode"
      :value="text"
      :style="Object.assign({}, { display: 'inline-block' }, textStyles)"
      :aria-label="label"
    />
  </SpecificationPanel>
</template>

<script>
import SpecificationPanel from './SpecificationPanel'

export default {
  components: {
    SpecificationPanel
  },
  props: {
    label: String,
    labelStyles: {
      type: Object,
      default: () => {
        return {}
      }
    },
    text: [String, Number],
    textStyles: {
      type: Object,
      default: () => {
        return {}
      }
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    getValue() {
      return this.text
    }
  }
}
</script>

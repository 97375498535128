import { appId } from '@/config'

export const methods = {
  async reload() {
    this.loading = true

    this.architecturalRequestID = this.$route.params.id || 0

    this.scope = appId()

    if (this.scope === 'management') {
      this.tabs.push({
        label: 'Vote Details',
        name: 'architecturalRequestVoteDetails'
      })
    }

    this.loading = false
  },

  returnToParent() {
    this.$router.push({
      name: 'architecturalRequests'
    })
  }
}

<template>
  <PageContentLayoutOnly role="region" architectural-details>
    <LinksMenu
      aria-label="architectural details tabs"
      :tabs="tabs"
      prefix="architectural."
      :style="{
        paddingLeft: '20px',
        paddingTop: '40px'
      }"
      :styles="{
        ul: {
          width: '140px'
        }
      }"
    >
      <section :style="{ overflowX: 'auto', flexGrow: 2, marginTop: '-95px' }">
        <SlideTransition :name="transitionName">
          <keep-alive>
            <router-view :key="$route.name" />
          </keep-alive>
        </SlideTransition>
      </section>
    </LinksMenu>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState } from 'vuex'
import SlideTransition from '@/components/transitions/Slide.vue'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'

import { data } from './keys/data'
import { methods } from './keys/methods'
import { watch } from './keys/watch'

import LinksMenu from '@/components/menus/Links'

export default {
  components: {
    LinksMenu,
    SlideTransition,
    PageContentLayoutOnly
  },

  data,

  computed: {
    ...mapState({
      unit: state => state.hoa.unit
    }),

    debug() {
      return this.unit
    }
  },

  watch,

  mounted() {
    this.reload()
  },

  methods,

  i18n: {
    messages: {
      en: { architectural: { title: 'Architectural Details' } }
    }
  }
}
</script>

<style lang="scss" scoped>
.owner-avatar {
  margin: 1em auto 2em;
}

.section {
  border: 0 !important;
  padding: 0 !important;
}

.container {
  border: 0 !important;
  padding: 0 !important;
}

.card {
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.05) !important;

  .card {
    border: 0 !important;
  }
}

.avatar-img {
  border: 1px solid #e7e8f2;
  padding: 0.25em;
}

.address-card {
  h5,
  h6 {
    margin: 0;
  }
}

.details-img {
  img {
    border-radius: 0.5em;
  }
}
</style>

/*
  A.K.A. Get the arch request document
*/

/*
  example:
    import { getFile } from '@/services/Architectural/ArchitecturalSubmission/GetFile';

    const { doc, successful, message } = await getFile ({
      params: {
        architecturalSubmissionID: '',
        asBase64: true
      }
    });
    if (!successful) {
      console.error (message);
      return;
    }
*/
//
import kms from '@/services/kms'
//
export async function getFile({ params }) {
  let message = `An architectural document could not be retrieved.`

  try {
    const result = await kms.get(`/Architectural/ArchitecturalSubmission/GetFile`, {
      params
    })

    return {
      successful: true,
      message: '',
      doc: result
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    doc: null
  }
}

///////

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContentLayoutOnly',{attrs:{"role":"region","architectural-details":""}},[_c('LinksMenu',{style:({
      paddingLeft: '20px',
      paddingTop: '40px'
    }),attrs:{"aria-label":"architectural details tabs","tabs":_vm.tabs,"prefix":"architectural.","styles":{
      ul: {
        width: '140px'
      }
    }}},[_c('section',{style:({ overflowX: 'auto', flexGrow: 2, marginTop: '-95px' })},[_c('SlideTransition',{attrs:{"name":_vm.transitionName}},[_c('keep-alive',[_c('router-view',{key:_vm.$route.name})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
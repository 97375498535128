<template>
  <div :style="{ display: 'flex' }" class="arch-requests--specifications">
    <div :style="{ flexBasis: '60%' }">
      <Specification
        label="Requested By"
        :text="requestDetails.ownerName"
        :textStyles="{ fontSize: '1.5rem' }"
      />

      <Specification label="Address" :text="requestDetails.address" />

      <CategorySpecification
        ref="category"
        label="Category"
        :text="requestDetails.category"
        :editMode="editMode"
      />

      <FieldSpecification
        ref="description"
        label="Description"
        :text="requestDetails.description"
        :editMode="editMode"
      />

      <FieldSpecification
        ref="detailedDescription"
        label="Detailed Description"
        :text="requestDetails.detailedDescription"
        :editMode="editMode"
      />

      <InputSpecification
        ref="fee"
        label="Fee Amount"
        :text="
          typeof requestDetails.feeAmount === 'number' ||
          (typeof requestDetails.feeAmount === 'string' && requestDetails.feeAmount.length >= 1)
            ? `$${requestDetails.feeAmount}`
            : ''
        "
        :editMode="editMode"
      />

      <InputSpecification
        ref="contractor"
        label="To be completed by"
        :text="requestDetails.toBeCompletedBy"
        :editMode="editMode"
      />

      <FieldSpecification
        label="Document Description"
        :text="requestDetails.submissionDocumentDescription"
      />

      <InputSpecification
        v-if="
          requestDetails.toBeCompletedBy === 'contractor' &&
            typeof requestDetails.toBeCompletedBy === 'string'
        "
        ref="contractor"
        label="Contractor"
        :text="requestDetails.contractor"
        :editMode="editMode"
      />
    </div>

    <div :style="{ flexBasis: '40%' }">
      <LabelSpecification
        ref="id"
        label="Id"
        :text="requestDetails.architecturalSubmissionId"
        :editMode="editMode"
      />
      <DateSpecification
        ref="submissionDate"
        label="Date Submitted"
        :text="requestDetails.submissionDate"
        :editMode="editMode"
      />
      <DateSpecification
        ref="respondByDate"
        label="Respond By Date"
        :text="requestDetails.respondByDate"
        :editMode="editMode"
      />

      <br />

      <DateSpecification
        ref="votingCompletionDate"
        label="Vote Completion Date"
        :text="requestDetails.votingCompleteDate"
        :editMode="editMode"
      />

      <br />

      <DateSpecification
        ref="estimatedCompletionDate"
        label="Estimated Completion Date"
        :text="requestDetails.estimatedCompletionDate"
        :editMode="editMode"
      />
    </div>
  </div>
</template>

<script>
import Specification from './Specification/Specification'
import CategorySpecification from './Specification/Category'
import FieldSpecification from './Specification/Field'
import LabelSpecification from './Specification/Label'
import InputSpecification from './Specification/Input'
import DateSpecification from './Specification/Date'
//

export default {
  components: {
    Specification,
    FieldSpecification,
    LabelSpecification,
    InputSpecification,
    CategorySpecification,
    DateSpecification
  },
  props: {
    requestDetails: Object,
    editMode: Boolean
  },
  data() {
    return {}
  },

  methods: {
    getValues() {
      return {
        category: this.$refs.category.getValue(),
        description: this.$refs.description.getValue(),
        detailedDescription: this.$refs.detailedDescription.getValue(),
        fee: this.$refs.fee.getValue(),
        submissionDate: this.$refs.submissionDate.getValue(),
        respondByDate: this.$refs.respondByDate.getValue(),
        estimatedCompletionDate: this.$refs.estimatedCompletionDate.getValue()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

.arch-requests--specifications {
  @media screen and (max-width: $desktop) {
    flex-direction: column;
  }
}
</style>

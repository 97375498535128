import { preventionMessage } from './methods'
import moment from 'moment'

export const PRESET_FORM_VALUES = {
  architecturalCategoryID: 0,
  ownerFilterQuery: '',
  description: '',
  detailedDescription: '',
  feeAmount: 0,
  toBeCompletedBy: '',
  contractor: '',
  estimatedCompletionDate: null,
  submissionDate: new Date().toISOString(),
  respondByDate: moment()
    .add('7', 'days')
    .utcOffset(0, true)
    .toISOString()
}

export function data() {
  const PRESET_ERROR_MESSAGES = {
    estimatedCompletionDate: null,
    votingCompleteDate: null,
    submissionDate: null,
    respondByDate: null
  }

  return {
    mode: 'homeowner',
    step: 0,
    showing: false,
    loading: false,
    filteredOwnersList: [],
    isFetching: false,
    defaultReviewFee: 0,
    formErrors: {
      ...PRESET_ERROR_MESSAGES
    },
    formData: {
      ...PRESET_FORM_VALUES
    },
    categories: [],
    subCategories: {
      landscaping: ['adding plants/bushes', 'removing plants/bushes', 'pavers', 'artificial turf']
    },
    toBeCompletedByOptions: ['homeowner', 'contractor'],
    disclaimer: `Please ensure that your request meets all requirements of the governing documents prior to submitting and include any necessary photos or documents with your request.

An application submitted without all 
necessary photos or documents will be considered incomplete. 

In such case, the review period will not 
commence until all required materials are provided. 

Additional materials may be requested to ensure 
thorough evaluation of the request.`,
    prevToolTip: '',
    prevPrevented: true,
    nextToolTip: preventionMessage(1),
    nextPrevented: true,
    isLastStep: false,
    documents: [],
    photos: [],
    base64pdf: []
  }
}

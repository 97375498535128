import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'
import { getAReqsList } from '@/services/Architectural/ArchitecturalSubmission/List'
import { statusTile } from '@/utilities/architectural/tile/status'
import { archReqCategories } from '@/services/Architectural/ArchitecturalCategory/store'
import moment from 'moment'
import SimpleButton from '@/components/buttons/Simple'

export const methods = {
  async updateTable({ filtersData }) {
    const status = filtersData.status

    if (status !== this.status) {
      this.table.loading = true

      this.status = status
      await this.getList()

      this.table.loading = false
    }
  },

  openAdder() {
    this.$refs.adder.show({
      mode: 'homeowner'
    })
  },

  sendRequest() {
    console.log('send request')
  },

  async parseRows({ list }) {
    if (!Array.isArray(list)) {
      this.table.rows = []
      this.table.loading = false
      return
    }

    const sortedList = _orderBy(list, 'submissionDate', 'desc')

    this.table.rows = sortedList.map(request => {
      const dateFormat = 'LL'

      var date = _get(request, 'submissionDate', '')

      try {
        date = moment(date).format(dateFormat)
      } catch (exception) {
        console.error(exception)
      }

      const statusText = _get(request, 'status', '')
      const { status } = statusTile({ statusText })
      const id = _get(request, 'architecturalSubmissionID', '')
      const architecturalCategoryID = _get(request, 'architecturalCategoryID', '')
      const category = this.categories.find(category => {
        const possibleId = _get(category, 'architecturalCategoryID', '')

        if (typeof possibleId === 'number') {
          if (possibleId === architecturalCategoryID) {
            return true
          }
        }

        return false
      })

      const categoryName = _get(category, 'name', '')

      return {
        id,
        category: categoryName,
        description: _get(request, 'description', ''),
        date: {
          date,
          format: dateFormat
        },
        status,
        details: {
          component: SimpleButton,
          props: {
            to: {
              path: `/architecturaltabs/${id}/architecturalRequestDetails#tab`,
              params: {
                id
              }
            },
            text: 'Details'
          }
        },
        askaquestion: {
          component: SimpleButton,
          props: {
            to: {
              name: 'newconversation',
              params: {
                architecturalsubmissionid: _get(request, 'architecturalSubmissionID', 0),
                archownerid: _get(request, 'ownerID', 0),
                archlabel: `Add Homeowner - ${_get(request, 'ownerName', '')}`
              }
            },
            text: 'Ask a Question'
          }
        }
      }
    })
    this.table.loading = false
  },

  async getList({ load = false } = {}) {
    if (load) {
      this.loading = true
    }

    const status = this.status

    console.log('getList', {
      status,

      approvedStatus: status === 'Approved' ? true : null,
      pendingStatus: status === 'Pending' ? true : null,
      deniedStatus: status === 'Denied' ? true : null,
      pendingNotVotedStatus: status === 'My Pending' ? true : null
    })

    const { list, successful, message } = await getAReqsList({
      params: {
        // required
        hoaID: this.currentHoaId,

        // optional
        ownerID: this.currentOwnerId,

        approvedStatus: status === 'Approved' ? true : null,
        pendingStatus: status === 'Pending' ? true : null,
        deniedStatus: status === 'Denied' ? true : null,
        pendingNotVotedStatus: status === 'My Pending' ? true : null
      }
    })
    console.debug('List Basic=' + JSON.stringify(list) + ', ' + successful + ', ' + message)
    if (!successful) {
      console.error(message)
      return
    }

    this.parseRows({
      list
    })

    if (load) {
      this.loading = false
    }
  },

  async refresh() {
    this.table.loading = true

    console.debug('refresh hoaId=' + this.currentHoaId)

    const { list: categories } = await archReqCategories.dispatch('List', {
      hoaId: this.currentHoaId
    })
    this.categories = categories

    await this.getList()
  }
}

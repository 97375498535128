var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    display: 'flex',
    /* borderBottom: '1px solid #f4f2f2', */
    padding: '3px',
    alignItems: 'center'
  })},[_c('div',{style:({
      display: 'inline-block',
      marginRight: '12px',
      flexBasis: '200px',
      flexGrow: 0,
      flexShrink: 0
    })},[_c('b-tag',{style:({ fontWeight: 'bold' })},[_vm._v(_vm._s(_vm.label))])],1),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }
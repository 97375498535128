<template>
  <SpecificationPanel :label="label">
    <span v-if="!editMode" :style="Object.assign({}, { display: 'inline-block' }, textStyles)">{{
      text
    }}</span>

    <b-select
      :aria-label="label"
      placeholder="Select a category"
      v-if="editMode"
      v-model="selectedValue"
      :value="text"
      :style="Object.assign({}, { display: 'inline-block' }, textStyles)"
    >
      <option v-for="option in list" :value="option.name" :key="option.id">
        {{ option.name }}
      </option>
    </b-select>
  </SpecificationPanel>
</template>

<script>
import { archReqCategories } from '@/services/Architectural/ArchitecturalCategory/store'
import { mapState } from 'vuex'

import SpecificationPanel from './SpecificationPanel'

export default {
  components: {
    SpecificationPanel
  },
  props: {
    label: String,
    labelStyles: {
      type: Object,
      default: () => {
        return {}
      }
    },
    text: [String, Number],
    textStyles: {
      type: Object,
      default: () => {
        return {}
      }
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selectedValue: this.text,
      list: []
    }
  },

  watch: {
    text(value) {
      this.selectedValue = value
    }
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  async mounted() {
    const { list } = await archReqCategories.dispatch('List', {
      hoaId: this.hoaId
    })

    this.list = list
  },

  methods: {
    getValue({ id = true } = {}) {
      const list = this.list

      if (id) {
        for (let a = 0; a < list.length; a++) {
          if (list[a].name === this.selectedValue) {
            return list[a].architecturalCategoryID
          }
        }

        return null
      }

      return this.selectedValue
    }
  }
}
</script>

/*
  page 1
    [manager]   ownerId
    [both]      architecturalCategoryID
    [both]      description (Work to be Done or Purpose of Request)
    [both]      toBeCompletedBy
    [both]      contractor [if toBeCompletedBy is 'contractor']
    [manager]   feeAmount
    [both]      detailedDescription
    [both]      estimatedCompletionDate
    [manager]   submissionDate
    [manager]   respondByDate
*/

export const watch = {
  'formData.ownerFilterQuery'() {
    this.checkPreventions()
  },
  'formData.architecturalCategoryID'() {
    this.checkPreventions()
  },
  'formData.description'() {
    this.checkPreventions()
  },
  'formData.toBeCompletedBy'() {
    this.checkPreventions()
  },
  'formData.contractor'() {
    this.checkPreventions()
  },

  'formData.feeAmount'() {
    this.checkPreventions()
    if (!this.formData.feeAmount || this.formData.feeAmount === 0) {
      this.loadArchSettingReviewFee()
    }
  },
  'formData.detailedDescription'() {
    this.checkPreventions()
  },

  'formData.estimatedCompletionDate'() {
    this.checkPreventions()
  },
  'formData.submissionDate'() {
    this.checkPreventions()
  },
  'formData.respondByDate'() {
    this.checkPreventions()
  },

  step() {
    this.checkPreventions()
  }
}

/*
  await archStipulations.dispatch ('deleteOne', {
    architecturalStipulationId
  })
*/

import kms from '@/services/kms'
import { notifyProblem } from '@/services/notify'

/* eslint-disable no-empty-pattern */
export async function deleteOne({}, { architecturalStipulationId }) {
  /* eslint-enable no-empty-pattern */
  try {
    await kms.delete(
      `/ArchitecturalVoting/ArchitecturalStipulation/${architecturalStipulationId}?forceDelete=true`
    )
  } catch (exception) {
    notifyProblem(`An architectural stipulation could not be deleted.`)
    console.error(exception)
  }

  return
}

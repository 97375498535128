/*
  example:
    import { storeArchDoc } from '@/services/Architectural/ArchitecturalSubmission/StoreDocument';

    const { successful, message } = await storeArchDoc ({
      doc,
      params: {
                hoaID,
                architecturalSubmissionID
      }
    });
    if (!successful) {
      console.error (message);
      return;
    }
*/
//
import kms from '@/services/kms'
//
const isDebug = true

export async function storeArchDoc({ doc, params }) {
  let message = `The architectural request could not be added.`

  try {
    const formData = new FormData()
    formData.append('file', doc)

    const path = `/Architectural/ArchitecturalSubmission/StoreDocument?architecturalSubmissionID=${params.architecturalSubmissionID}&description=${params.description}`

    const result = await kms.post(path, formData, {
      'Content-Type': 'multipart/form-data'
    })

    if (isDebug == true)
      console.debug(result.documentId + ' storeArchDoc=' + JSON.stringify(result))

    return {
      successful: true,
      message: '',
      documentID: result.documentId
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message
  }
}

<template>
  <div class="section">
    <div class="container">
      <div class="is-size-4" v-if="showTitle">Photo (optional)</div>

      <div class="box is-8">
        <section class="has-text-centered" v-if="loadedPhotos">
          <div style="padding-bottom:10px;" v-for="(c, index) in loadedPhotos" :key="index">
            <div v-if="c.content" :style="{ position: 'relative', textAlign: 'left' }">
              <img :src="c.content" />

              <b-button
                v-if="c.content"
                type="is-danger"
                outlined
                :style="{ margin: '20px 0 0' }"
                @click="confirmDeletePhoto(c, index)"
                >Delete</b-button
              >
            </div>

            <div v-if="c.content"><hr /></div>
          </div>
        </section>

        <section>
          <!-- Show the Upload Form -->
          <b-field class="file">
            <b-upload v-model="file" drag-drop accept="image/png">
              <section class="section has-width-500">
                <div class="content has-text-centered">
                  <p v-if="file">
                    {{ file.name }}
                  </p>
                  <p v-else>
                    Drop a PNG file here, or click to select a file from your computer.
                  </p>
                </div>
              </section>
            </b-upload>
          </b-field>
          <b-button type="is-primary" :disabled="!file" @click="submitFile">Upload</b-button>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import { ToastProgrammatic as Toast } from 'buefy'
import { dragscroll } from 'vue-dragscroll'
import { mapState, mapActions } from 'vuex'
import kms from '@/services/kms'
import { archReqAttachments } from '@/services/Architectural/ArchitecturalSubmissionAttachment/store'

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

export default {
  name: 'ImageModal',

  directives: { dragscroll },

  props: {
    architecturalSubmissionID: Number,
    reloadPhotos: Boolean,
    showTitle: {
      type: Boolean,
      default: false
    }
  },

  data: function() {
    return {
      loading: false,
      isDebug: true,
      file: null,
      loadedPhotos: [{ index: '', content: 0 }],
      scale: 1
    }
  },

  mounted() {
    this.loadCurrentPhotos()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {
    architecturalSubmissionID() {
      this.loadCurrentPhotos()
    },
    reloadPhotos() {
      if (this.isDebug == true) console.debug('... in reloadPhotos=' + this.reloadPhotos)
      if (this.reloadPhotos && this.reloadPhotos != undefined && this.reloadPhotos == true) {
        this.loadCurrentPhotos()
      }
    }
  },

  methods: {
    ...mapActions('architectural', ['uploadRequestAttachmentDocument']),

    async loadCurrentPhotos() {
      this.loading = true
      this.loadedPhotos = [{}]

      try {
        const { results: attachments } = await kms.get(
          `/Architectural/ArchitecturalSubmissionAttachment/List`,
          {
            params: {
              hoaID: this.hoaId,
              architecturalSubmissionID: this.architecturalSubmissionID
            }
          }
        )

        if (attachments && attachments != undefined && attachments.length > 0) {
          if (this.isDebug == true) {
            console.debug('results' + JSON.stringify(attachments))
          }

          if (this.isDebug == true) {
            console.debug(
              'attachments.architecturalSubmissionAttachmentID' +
                attachments[0].architecturalSubmissionAttachmentID
            )
          }

          let counter = 0

          for (let i = 0; i < attachments.length; i++) {
            if (
              attachments[i] &&
              attachments[i] != undefined &&
              attachments[i].architecturalSubmissionAttachmentID &&
              attachments[i].architecturalSubmissionAttachmentID != undefined
            ) {
              const results2 = await kms.get(
                '/Architectural/ArchitecturalSubmissionAttachment/GetAttachmentFile',
                {
                  params: {
                    architecturalSubmissionAttachmentID:
                      attachments[i].architecturalSubmissionAttachmentID,
                    asBase64: true
                  }
                }
              )

              counter += 1
              if (results2 && results2 != undefined) {
                this.loadedPhotos.push({
                  index: counter,
                  content: results2,
                  id: attachments[i].architecturalSubmissionAttachmentID
                })
              }
            }
          }
        }
      } catch (e) {
        notifyError(e)
      }

      this.imageModalToggle = false
      this.loading = false
    },

    confirmDeletePhoto(photo) {
      this.$buefy.dialog.confirm({
        title: 'Deleting Photo',
        message: `Are you sure you want to <b>Delete</b> this photo? <img src="${photo.content}" style="max-width: 102px; max-height: 102px; margin: 20px auto; border-radius: 4px;" />`,
        confirmText: 'Delete Photo',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          await this.deletePhoto(photo)
          await this.loadCurrentPhotos()
        }
      })
    },
    async deletePhoto(photo) {
      console.log('Delete id' + photo.id)

      await archReqAttachments.dispatch('Erase', {
        id: photo.id
      })
    },

    closeModal() {
      //this.docModalToggle = false
      this.$emit('update:toggle', (this.docModalToggle = false))
      if (this.isDebug == true) console.debug('docModal=' + this.docModalToggle)
    },
    zoom(direction) {
      const amt = Math.sqrt(2)
      if (direction > 0) {
        this.scale *= amt
      } else {
        this.scale /= amt
      }

      // constrain to min/max
      this.scale = Math.max(0.5, Math.min(4, this.scale))
    },
    //
    //
    async submitFile() {
      if (this.architecturalSubmissionID <= 0) {
        notifyError('There was a problem uploading this image.')
        return
      }

      try {
        await archReqAttachments.dispatch('StoreAttachment', {
          file: this.file,
          params: {
            newFileName: this.file.name ? this.file.name : 'new file name',
            existingArchitecturalSubmissionID: this.architecturalSubmissionID
          }
        })

        this.loadCurrentPhotos()
      } catch (e) {
        notifyError(e)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.modal-h-full {
  left: 50%;
  transform: translate(-50%);
}

#request-image-upload {
  .upload .upload-draggable {
    border: none 0;
    border-radius: 0;
    width: 100%;
    padding: 0;
  }

  .modal-close,
  .delete {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    margin: 5px 5px 0 0;
  }
}

.doc-viewer {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 130%;
  min-height: 40vh;
  max-height: calc(100vh - 320px);
  border: 1px solid #dbdbdb;

  &.zoom-active {
    cursor: grab;
  }
}

.doc-controls {
  margin-top: -1em;
}
</style>

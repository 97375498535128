import _get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import parseAddress from '@/utilities/address/parse'
import { parseDate } from '@/utilities/Date/parse'
import { notifyProblem } from '@/services/notify'
import { archStipulations } from '@/services/ArchitecturalVoting/ArchitecturalStipulation/store'
import { archVoteNotes } from '@/services/ArchitecturalVoting/ArchitecturalVoteNote/store'
import { archVotes } from '@/services/ArchitecturalVoting/ArchitecturalVote/store'
import { archReqs } from '@/services/Architectural/ArchitecturalSubmission/store'
import { getFile } from '@/services/Architectural/ArchitecturalSubmission/GetFile'
import { getAttachments } from '@/services/Architectural/ArchitecturalSubmissionAttachment/sequences/getAttachments'
import { deleteArchAttachment } from '@/services/Architectural/ArchitecturalSubmissionAttachment/Delete'
import { storeAttachments } from '@/services/Architectural/ArchitecturalSubmissionAttachment/sequences/storeAttachments'
import { storeArchDoc } from '@/services/Architectural/ArchitecturalSubmission/StoreDocument'
import { notifyError, notifyMessage } from '@/services/notify'
import $ from 'jquery'

export const methods = {
  async confirmDeleteFinalStipulation(text) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Final Stipulation',
      message: `Are you sure you want to <b>delete</b> "${text}"?`,
      confirmText: 'Delete Final Stipulation',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => await this.deleteFinalStipulation(text)
    })
  },

  async deleteFinalStipulation(text) {
    if (text) {
      try {
        let afterDeletedStipulations = []
        if (this.finalStipulations) {
          Array.from(this.finalStipulations).forEach(stip => {
            if (stip !== text) {
              afterDeletedStipulations.push(stip)
            }
          })
        }

        console.debug(
          'finalStipulaafterDeletedStipulationstions=' + JSON.stringify(afterDeletedStipulations)
        )

        await archReqs.dispatch('put', {
          json: {
            hoaID: this.currentArchitecturalSubmission.hoaID,
            ownerID: this.currentArchitecturalSubmission.ownerID,
            architecturalSubmissionID: this.currentArchitecturalSubmission
              .architecturalSubmissionID,
            arTransactionID: this.currentArchitecturalSubmission.arTransactionID,
            submissionDocumentID: this.currentArchitecturalSubmission.submissionDocumentID,
            submissionDocumentDescription: this.currentArchitecturalSubmission
              .submissionDocumentDescription,
            architecturalCategoryID: this.currentArchitecturalSubmission.architecturalCategoryID,
            description: this.currentArchitecturalSubmission.description,
            detailedDescription: this.currentArchitecturalSubmission.detailedDescription,
            feeAmount: this.currentArchitecturalSubmission.feeAmount,
            wasApproved: this.currentArchitecturalSubmission.wasApproved,
            votingCompleteDate: this.currentArchitecturalSubmission.votingCompleteDate,
            estimatedCompletionDate: this.currentArchitecturalSubmission.estimatedCompletionDate,
            submissionDate: this.currentArchitecturalSubmission.submissionDate,
            respondByDate: this.currentArchitecturalSubmission.respondByDate,
            toBeCompletedBy: this.currentArchitecturalSubmission.toBeCompletedBy,
            contractor: this.currentArchitecturalSubmission.contractor,
            cancelDate: this.currentArchitecturalSubmission.cancelDate,
            cancelReason: this.currentArchitecturalSubmission.cancelReason,
            cancelledByAspNetUserID: this.currentArchitecturalSubmission.cancelledByAspNetUserID,
            denialType: this.currentArchitecturalSubmission.denialType,
            committeeID: this.currentArchitecturalSubmission.committeeID,
            stipulationsJSON: JSON.stringify(afterDeletedStipulations),
            denialReasonsJSON: this.currentArchitecturalSubmission.denialReasonsJSON
          }
        })

        notifyMessage(`This final stipulation, "${text}",  has been successfully deleted.`)

        await this.refreshFinalJSON()
      } catch (e) {
        notifyError('There was a problem deleting this final stipulation.')
      }
    }
  },

  async confirmDeleteFinalDenial(text) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Final Denial Reason',
      message: `Are you sure you want to <b>delete</b> "${text}"?`,
      confirmText: 'Delete Final Denial Reason',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => await this.deleteFinalDenial(text)
    })
  },

  async deleteFinalDenial(text) {
    if (text) {
      try {
        let afterDeletedDenials = []
        if (this.finalDenials) {
          Array.from(this.finalDenials).forEach(denial => {
            if (denial !== text) {
              afterDeletedDenials.push(denial)
            }
          })
        }

        console.debug('afterDeletedDenials=' + JSON.stringify(afterDeletedDenials))

        await archReqs.dispatch('put', {
          json: {
            hoaID: this.currentArchitecturalSubmission.hoaID,
            ownerID: this.currentArchitecturalSubmission.ownerID,
            architecturalSubmissionID: this.currentArchitecturalSubmission
              .architecturalSubmissionID,
            arTransactionID: this.currentArchitecturalSubmission.arTransactionID,
            submissionDocumentID: this.currentArchitecturalSubmission.submissionDocumentID,
            submissionDocumentDescription: this.currentArchitecturalSubmission
              .submissionDocumentDescription,
            architecturalCategoryID: this.currentArchitecturalSubmission.architecturalCategoryID,
            description: this.currentArchitecturalSubmission.description,
            detailedDescription: this.currentArchitecturalSubmission.detailedDescription,
            feeAmount: this.currentArchitecturalSubmission.feeAmount,
            wasApproved: this.currentArchitecturalSubmission.wasApproved,
            votingCompleteDate: this.currentArchitecturalSubmission.votingCompleteDate,
            estimatedCompletionDate: this.currentArchitecturalSubmission.estimatedCompletionDate,
            submissionDate: this.currentArchitecturalSubmission.submissionDate,
            respondByDate: this.currentArchitecturalSubmission.respondByDate,
            toBeCompletedBy: this.currentArchitecturalSubmission.toBeCompletedBy,
            contractor: this.currentArchitecturalSubmission.contractor,
            cancelDate: this.currentArchitecturalSubmission.cancelDate,
            cancelReason: this.currentArchitecturalSubmission.cancelReason,
            cancelledByAspNetUserID: this.currentArchitecturalSubmission.cancelledByAspNetUserID,
            denialType: this.currentArchitecturalSubmission.denialType,
            committeeID: this.currentArchitecturalSubmission.committeeID,
            stipulationsJSON: this.currentArchitecturalSubmission.stipulationsJSON,
            denialReasonsJSON: JSON.stringify(afterDeletedDenials)
          }
        })

        notifyMessage(`This final denial, "${text}",  has been successfully deleted.`)

        await this.refreshFinalJSON()
      } catch (e) {
        notifyError('There was a problem deleting this final denial reason.')
      }
    }
  },

  async retrievePhotos() {
    this.loading.photos = true

    const architecturalSubmissionID = this.architecturalSubmissionID
    const hoaID = this.hoaId

    const { results } = await getAttachments({
      architecturalSubmissionID,
      hoaID
    })

    const photos = []
    for (let a = 0; a < results.length; a++) {
      const result = results[a]

      if (_get(result, 'successful', false) === true) {
        photos.push({
          content: _get(result, 'photo', ''),
          id: _get(result, 'id', '')
        })
      }
    }

    this.photos = photos
    this.loading.photos = false
  },

  async retrieveDocument() {
    this.loading.doc = true

    const architecturalSubmissionID = this.architecturalSubmissionID

    if (this.hasDocument == true) {
      const { doc, successful, message } = await getFile({
        params: {
          architecturalSubmissionID,
          asBase64: true
        }
      })
      if (!successful) {
        console.error(message)
        this.loading.doc = false
        return
      }

      if (doc && doc != undefined) {
        this.doc.content = doc
      }
    }

    await new Promise(resolve => {
      setTimeout(() => {
        this.loading.doc = false
        resolve()
      }, 400)
    })
  },

  async openPDFModal() {
    if (this.editMode) {
      return
    }

    this.$refs.pdfModal.open({
      src: this.doc.content
    })
  },
  async openPDFUploader() {
    this.pdfUploader.active = true
    this.$forceUpdate()
  },
  async updatePDF({ documents }) {
    this.potentialPDFs = documents
  },
  async uploadNewPDF() {
    const potentialPDFs = this.potentialPDFs

    if (Array.isArray(potentialPDFs) && potentialPDFs.length >= 1) {
      this.pdfUploader.loading = true

      const architecturalSubmissionID = this.architecturalSubmissionID
      const hoaID = this.hoaId

      const {
        successful: docSaved,
        message: docMessage,
        documentID: documentID
      } = await storeArchDoc({
        doc: potentialPDFs[0],
        params: {
          hoaID,
          architecturalSubmissionID
        }
      })
      if (!docSaved || documentID <= 0) {
        notifyProblem(docMessage)
      }

      this.hasDocument = true
      this.documentID = documentID

      if (this.isDebug == true) console.debug('uploadNewPDF documentID=' + this.documentID)

      await this.retrieveDocument()

      this.pdfUploader.loading = false
      this.pdfUploader.active = false
      this.$forceUpdate()
    } else {
      notifyProblem('There does not seem to be a PDF selected for upload.')
    }
  },
  async erasePDF() {
    console.log('erasePDF')
  },

  async openPhotoUploader() {
    this.photoUploader.active = true
    this.$forceUpdate()
  },
  async uploadNewPhoto() {
    const photos = this.potentialPhotos
    const hoaID = this.hoaID
    const architecturalSubmissionID = this.architecturalSubmissionID

    if (Array.isArray(photos) && photos.length >= 1) {
      const { problems } = await storeAttachments({
        hoaID,
        architecturalSubmissionID,

        attachments: photos
      })
      if (problems.length >= 1) {
        notifyProblem(`${problems.length} of the photos may not have been uploaded successfully`)
      }

      await this.retrievePhotos()

      this.photoUploader.loading = false
      this.photoUploader.active = false
      this.$forceUpdate()
    }
  },
  async updatePhotos({ items }) {
    this.potentialPhotos = items
  },

  async erasePhoto({ index }) {
    const id = _get(this, ['photos', index, 'id'], null)

    await new Promise(resolve => {
      this.$buefy.dialog.confirm({
        message: `Are you sure you want to delete this photo?`,
        confirmText: 'Yes',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          resolve()
        }
      })
    })

    this.loading.photos = true

    if (typeof id !== 'number' || id < 1) {
      notifyProblem('A problem occurred and the photo could not be deleted')
      this.loading.photos = false
      return
    }

    const { successful, message } = await deleteArchAttachment({
      id
    })
    if (!successful) {
      notifyProblem(message)
      this.loading.photos = false
      return
    }

    this.retrievePhotos()
  },

  async openPhotoModal(index) {
    if (this.editMode === true) {
      return
    }

    this.photoModal = {
      open: true,
      src: this.photos[index].content
    }
  },

  denyRequest() {
    this.saveEdits({
      denied: true
    }).then(() => {
      notifyMessage('This architectural request has been successfully Denied.')
    })
  },
  async approveRequest() {
    if (this.finalStipulations.length === 0 && this.stipulationList.length > 0) {
      await this.confirmNoFinalStipulations()
    } else {
      this.saveEdits({
        approved: true
      }).then(() => {
        notifyMessage('This architectural request has been successfully Approved.')
      })
    }
  },

  async confirmNoFinalStipulations() {
    this.$buefy.dialog.confirm({
      title: 'Finalize Stipulations',
      message: `Stipulations have been submitted, but no stipulations are added to the final list. Are you sure that you want to approve without any stipulations?`,
      confirmText: 'Finalize Stipulations',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () =>
        await this.saveEdits({
          approved: true
        })
    })
  },

  ///‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾
  //  denial modal
  //
  openDenialModal({ member }) {
    const approvalStatus = _get(this, ['requestDetails', 'wasApproved'], null)
    if (typeof approvalStatus === 'boolean') {
      if (approvalStatus === true) {
        notifyProblem('This request has already been approved.')
      } else {
        notifyProblem('This request has already been denied.')
      }
      return
    }

    let note = _get(member, ['notes', 0, 'text'], '')
    if (typeof note !== 'string') {
      note = ''
    }

    const updates = {
      member,
      active: true,
      data: {
        note,
        reason: member.reasonForDenial
      }
    }
    for (const key in updates) {
      this.$set(this.denialModal, key, updates[key])
    }
  },
  clearDenialModal({ active = true } = {}) {
    let updates = cloneDeep(this.denialModalPresets)
    updates.active = active

    for (const key in updates) {
      this.$set(this.denialModal, key, updates[key])
    }
  },
  async denialModalSave() {
    const isValid = await this.$refs.denialModalObserver.validate()
    if (!isValid) {
      return
    }

    this.loading.committee = true
    this.denialModal.loading = true

    const noteText = this.denialModal.data.note

    //
    const member = this.denialModal.member
    const notesCount = member.notes.length
    const architecturalVoteID = member.architecturalVoteID

    await archVotes.dispatch('UpdateVoteOnly', {
      json: {
        hoaID: this.hoaId,
        architecturalVoteID,
        committeeMemberID: this.denialModal.member.committeeMemberID,

        disapproveReason: this.denialModal.data.reason,
        wasApproved: false,

        ...(notesCount === 0 && noteText.length >= 1
          ? {
              voteNote: noteText
            }
          : {})
      }
    })

    /*
      update the note if notesCount >= 1
    */
    if (notesCount >= 1) {
      const architecturalVoteNoteID = _get(member, ['notes', 0, 'id'], null)

      if (noteText.length >= 1) {
        await archVoteNotes.dispatch('put', {
          json: {
            architecturalVoteID,
            architecturalVoteNoteID,
            note: noteText
          }
        })
      } else {
        await archVoteNotes.dispatch('erase', {
          architecturalVoteNoteID
        })
      }
    }

    const architecturalSubmissionID = this.architecturalSubmissionID
    await this.updateVotes({
      architecturalSubmissionID
    })

    this.clearDenialModal({
      active: false
    })

    this.loading.committee = false
    this.denialModal.loading = false

    notifyMessage('You have successfully marked this request as denied.')
  },
  //\____________________
  //

  ///‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾
  //  approval modal
  //
  async openApprovalModal({ member }) {
    const approvalStatus = _get(this, ['requestDetails', 'wasApproved'], null)
    if (typeof approvalStatus === 'boolean') {
      if (approvalStatus === true) {
        notifyProblem('This request has already been approved.')
      } else {
        notifyProblem('This request has already been denied.')
      }
      return
    }

    await this.clearApprovalModal()

    const { list: stipulations } = await this.loadStipulations({
      architecturalSubmissionID: member.architecturalSubmissionID,
      architecturalVoteID: member.architecturalVoteID
    })

    this.stipulationList = stipulations

    let note = _get(member, ['notes', 0, 'text'], '')
    if (typeof note !== 'string') {
      note = ''
    }

    const updates = {
      member,
      stipulation: '',
      stipulationDeletions: [],
      notesCount: member.notes.length,
      data: {
        note,
        stipulations: stipulations.map(s => {
          return {
            architecturalStipulationID: s.architecturalStipulationID,
            text: s.stipulation,
            date: parseDate(s.createdDate, 'LL')
          }
        })
      },
      loading: false
    }
    for (const key in updates) {
      this.$set(this.approvalModal, key, updates[key])
    }
  },
  async clearApprovalModal({ active = true } = {}) {
    let updates = cloneDeep(this.approvalModalPresets)
    updates.active = active

    for (const key in updates) {
      this.$set(this.approvalModal, key, updates[key])
    }
  },
  async loadStipulations({ architecturalSubmissionID, architecturalVoteID }) {
    const hoaId = this.hoaId

    const { list } = await archStipulations.dispatch('getList', {
      params: {
        hoaID: hoaId,
        architecturalSubmissionID,
        architecturalVoteID
      }
    })

    return { list }
  },
  addStipulation() {
    const stipulation = this.approvalModal.stipulation

    if (stipulation.length === 0) {
      return
    }

    this.approvalModal.data.stipulations.push({
      id: null,
      text: stipulation,
      date: parseDate(new Date().toISOString(), 'LL')
    })
  },
  deleteStipulation(index) {
    const [stipulation] = this.approvalModal.data.stipulations.splice(index, 1)
    console.debug('deleteStipulation=' + JSON.stringify(stipulation))

    if (
      stipulation &&
      stipulation !== undefined &&
      stipulation.architecturalStipulationID &&
      stipulation.architecturalStipulationID > 0
    ) {
      this.approvalModal.stipulationDeletions.push(stipulation)
    }
  },
  async approvalModalSave() {
    this.approvalModal.loading = true

    this.loading.committee = true
    this.loading.voteDetails = true

    const isValid = await this.$refs.approvalModalObserver.validate()

    if (!isValid) {
      return
    }

    const member = this.approvalModal.member
    const hoaId = this.hoaId
    const architecturalVoteID = member.architecturalVoteID
    const noteText = this.approvalModal.data.note
    const notesCount = member.notes.length

    /*
      update notes and approval status
    */
    await archVotes.dispatch('UpdateVoteOnly', {
      json: {
        hoaID: hoaId,
        //
        wasApproved: true,
        //
        architecturalVoteID,
        committeeMemberID: this.approvalModal.member.committeeMemberID,

        ...(notesCount === 0 && noteText.length >= 1
          ? {
              voteNote: noteText
            }
          : {})
      }
    })

    /*
      update the note if notesCount >= 1
    */
    if (notesCount >= 1) {
      const architecturalVoteNoteID = _get(member, ['notes', 0, 'id'], null)

      if (noteText.length >= 1) {
        await archVoteNotes.dispatch('put', {
          json: {
            architecturalVoteID,
            architecturalVoteNoteID,
            note: noteText
          }
        })
      } else {
        await archVoteNotes.dispatch('erase', {
          architecturalVoteNoteID
        })
      }
    }

    /*
      update stipulations
        delete deleted stipulations
        add added stipulations
    */
    await archStipulations.dispatch('deleteAndAddMultiple', {
      // array of integers
      toRemove: this.approvalModal.stipulationDeletions.map(deletion => {
        return deletion.architecturalStipulationID
      }),
      toAdd: this.approvalModal.data.stipulations
        .filter(stipulation => {
          return stipulation.id === null
        })
        .map(stipulation => {
          return {
            architecturalVoteID: this.approvalModal.member.architecturalVoteID,
            stipulation: stipulation.text
          }
        })
    })

    await this.clearApprovalModal({
      active: false
    })

    /*
      db (add/remove) updates might not all be finished
      before next list retrieval request is made
    */
    await new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, 1000)
    })

    const architecturalSubmissionID = this.architecturalSubmissionID
    await this.updateVotes({
      architecturalSubmissionID
    })

    this.loading.committee = false
    this.loading.voteDetails = false

    notifyMessage('You have successfully marked this request as approved.')

    // notifyMessage ('Approval vote was recorded successfully.');
  },
  //\____________________
  //

  async refreshFinalJSON() {
    const { result } = await archReqs.dispatch('getDetails', {
      architecturalSubmissionID: this.requestDetails.architecturalSubmissionId
    })

    if (result) {
      this.finalStipulations = _get(result, 'stipulations', [])
      this.finalDenials = _get(result, 'denialReasons', [])
      this.currentArchitecturalSubmission.stipulationsJSON = _get(result, 'stipulationsJSON', null)
      this.currentArchitecturalSubmission.denialReasonsJSON = _get(
        result,
        'denialReasonsJSON',
        null
      )
    }
  },

  async refreshRequestPage({ id }) {
    this.loading.requestSpecifications = true
    this.loading.committee = true
    this.loading.voteDetails = true

    const { list } = await archVotes.dispatch('getList', {
      params: {
        hoaId: this.hoaId,
        architecturalSubmissionID: id
      }
    })
    if (list.length >= 1) {
      this.parseRequestDetails({
        details: list[0].architecturalSubmission
      })
    } else {
      /*
        if their aren't any voters,
        get the details of the request
        from the archReqs api
      */
      const { result } = await archReqs.dispatch('getDetails', {
        architecturalSubmissionID: id
      })

      this.parseRequestDetails({
        details: result
      })

      this.currentArchitecturalSubmission = result
    }

    this.parseCommitteeDetails({
      list
    })

    this.loading.requestSpecifications = false
    this.loading.committee = false
    this.loading.voteDetails = false
  },

  //
  //  Voting Committee Card
  //
  async updateVotes({ architecturalSubmissionID }) {
    if (
      typeof architecturalSubmissionID !== 'number' &&
      typeof architecturalSubmissionID !== 'string'
    ) {
      notifyProblem('A problem occurred, the committee members votes could not be retrieved.')
      return
    }

    const { list } = await archVotes.dispatch('getList', {
      params: {
        hoaID: this.hoaId,
        architecturalSubmissionID
      }
    })

    this.parseCommitteeDetails({
      list
    })
  },

  //
  // sets 'this.requestDetails'
  parseRequestDetails({ details }) {
    console.debug('in parseRequestDetails...')
    this.currentArchitecturalSubmission = details
    console.debug(
      'currentArchitecturalSubmission=' + JSON.stringify(this.currentArchitecturalSubmission)
    )

    if (_get(details, ['submissionDocumentID'], 0) > 0) {
      this.hasDocument = true
      this.documentID = _get(details, ['submissionDocumentID'], 0)
    }

    const toBeCompletedBy = _get(details, ['toBeCompletedBy'], '')

    let contractor = null
    const _contractor = _get(details, ['contractor'], '')
    if (toBeCompletedBy === 'contractor') {
      if (typeof _contractor === 'string' && _contractor.length >= 1) {
        contractor = _contractor
      }
    }

    this.finalStipulations = _get(details, ['stipulations'], [])
    this.finalDenials = _get(details, ['denialReasons'], [])

    console.debug('final stipulations=' + JSON.stringify(this.finalStipulations))
    console.debug('final denials=' + JSON.stringify(this.finalDenials))

    this.requestDetails = {
      architecturalSubmissionId: _get(details, ['architecturalSubmissionID'], null),
      ownerId: _get(details, ['owner', 'ownerID'], null),

      ownerName: _get(details, ['owner', 'name'], null),
      address: parseAddress({
        address: _get(details, ['owner', 'unit', 'address'], null)
      }),

      submissionDocumentID: _get(details, ['submissionDocumentID'], 0),
      category: _get(details, ['architecturalCategory', 'name'], ''),
      description: _get(details, ['description'], ''),
      detailedDescription: _get(details, ['detailedDescription'], ''),
      feeAmount: _get(details, ['feeAmount'], ''),
      contractor,
      toBeCompletedBy,
      wasApproved: _get(details, ['wasApproved'], '?'),
      respondByDate: _get(details, ['respondByDate'], ''),
      estimatedCompletionDate: _get(details, ['estimatedCompletionDate'], ''),
      submissionDate: _get(details, ['submissionDate'], ''),
      votingCompleteDate: _get(details, ['votingCompleteDate'], ''),
      architecturalSubmissionAttachments: _get(details, ['architecturalSubmissionAttachments'], [])
    }
  },

  //
  // sets 'this.committeeMembers'
  parseCommitteeDetails({ list }) {
    this.results = {
      yes: 0,
      no: 0,
      pending: 0
    }
    this.isConcludable = false
    this.isApprovable = false
    this.isDeniable = false

    let isApprovable = false
    let isDeniable = false

    if (Array.isArray(list)) {
      const committeeMembers = list.map(s => {
        const isAuthenticated = _get(s, ['committeeMember', 'isAuthenticated'], false)

        const decision = _get(s, ['decision'], null)
        if (decision === true) {
          this.results['yes']++
        } else if (decision === null) {
          this.results['pending']++
        } else if (decision === false) {
          this.results['no']++
        }

        isApprovable = _get(s, ['isApprovable'], null)
        isDeniable = _get(s, ['isDeniable'], null)

        let reasonForDenial = _get(s, ['disapproveReason'], '')
        if (typeof reasonForDenial !== 'string') {
          reasonForDenial = ''
        }

        let stipulations = _get(s, ['architecturalStipulations'], [])
        if (!Array.isArray(stipulations)) {
          stipulations = []
        }

        let notes = _get(s, ['architecturalVoteNotes'], [])
        if (!Array.isArray(notes)) {
          notes = []
        }

        return {
          committeeMemberID: _get(s, ['committeeMemberID'], ''),
          architecturalVoteID: _get(s, ['architecturalVoteID'], ''),
          name: _get(s, ['committeeMember', 'name'], ''),
          decision,
          isAuthenticated,
          notes: notes.map(note => {
            let text = _get(note, 'note', '')
            if (typeof text !== 'string') {
              text = ''
            }

            return {
              text,
              id: _get(note, 'architecturalVoteNoteID', null)
            }
          }),
          stipulations: stipulations.map(stipulation => {
            let text = _get(stipulation, 'stipulation', '')
            if (typeof text !== 'string') {
              text = ''
            }

            return {
              text
            }
          }),
          reasonForDenial
        }
      })

      this.committeeMembers = committeeMembers
      this.stipulationList = []

      if (this.committeeMembers) {
        Array.from(committeeMembers).forEach(m => {
          if (m) {
            if (m.stipulations && m.stipulations.length > 0) {
              m.stipulations = m.stipulations.map(s => ({
                ...s,
                committeeName: m.name,
                committeeMemberID: m.committeeMemberID
              }))

              Array.from(m.stipulations).forEach(m2 => {
                if (m2) {
                  this.stipulationList.push(m2)
                }
              })
            }

            if (m.notes && m.notes.length > 0) {
              m.notes = m.notes.map(d => ({
                ...d,
                committeeName: m.name,
                committeeMemberID: m.committeeMemberID
              }))

              Array.from(m.notes).forEach(m2 => {
                if (m2) {
                  this.denialList.push(m2)
                }
              })
            }
          }
        })

        console.debug('stipulationList=' + JSON.stringify(this.stipulationList))
      }

      if (isApprovable == true) {
        this.isConcludable = true
        this.isApprovable = true
        this.hideFinalDenials()
      } else if (isDeniable == true) {
        this.isConcludable = true
        this.isDeniable = true
        this.hideFinalStipulations()
      } else {
        this.hideFinalDenials()
        this.hideFinalStipulations()
      }
    }
  },

  hideFinalDenials() {
    $("span:contains('Final Denials')")
      .parent()
      .parent()
      .css('display', 'none')
  },

  hideFinalStipulations() {
    $("span:contains('Final Stipulations')")
      .parent()
      .parent()
      .css('display', 'none')
  },

  hideFinalizeTabs() {
    this.hideFinalDenials()
    this.hideFinalStipulations()
  },

  openEditMode() {
    if (this.editMode) {
      this.confirmFinishEditingWithoutSaving()
    } else {
      this.editMode = true
    }
  },

  confirmFinishEditingWithoutSaving() {
    this.$buefy.dialog.confirm({
      message: `Are you sure you want to finish editing without saving?`,
      confirmText: 'Yes',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        this.editMode = false
      }
    })
  },

  addStipulationModal(stipulation) {
    if (stipulation) {
      this.toggle = true
      this.selectedStipulation = stipulation
    }
  },

  addDenialModal(denial) {
    if (denial) {
      this.toggleDenial = true
      this.selectedDenial = denial
    }
  },

  async saveEdits({ approved = false, denied = false } = {}) {
    if (this.isDebug == true)
      console.debug(
        this.requestDetails.submissionDocumentID +
          ' saveEdits documentID=' +
          this.documentID +
          ' apoproved=' +
          approved +
          ', denied=' +
          denied
      )

    this.loading.requestSpecifications = true
    this.loading.committee = true
    this.loading.voteDetails = true

    //const specifications = this.$refs.specifications
    //const values = specifications.getValues()
    //const feeAmount = values.fee.substring(1)

    const payload = {
      architecturalSubmissionID: this.requestDetails.architecturalSubmissionId,
      ownerID: this.requestDetails.ownerId
    }

    await archReqs.dispatch('updateDenyOrApprove', {
      payload: payload,
      done: () => {}
    })

    this.editMode = false

    await this.refreshRequestPage({
      id: this.requestDetails.architecturalSubmissionId
    })
  }
}

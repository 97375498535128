/*
  example:
    import { initArchReq } from '@/services/Architectural/ArchitecturalSubmission/CreateAndInitializeVotes';

    const { result, successful, message } = await initArchReq ({
      json: {
  
      }
    });
    if (!successful) {
      console.error (message);
      return;
    }
*/
//
import kms from '@/services/kms'
//
export async function initArchReq({ json } = {}) {
  let message = `The architectural request could not be opened.`

  try {
    const result = await kms.post(
      `/Architectural/ArchitecturalSubmission/CreateAndInitializeVotes`,
      json
    )

    return {
      successful: true,
      message: '',
      result
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    result: null
  }
}

///////

import { archReqs } from '@/services/Architectural/ArchitecturalSubmission/store'
import { notifyError, notifyMessage } from '@/services/notify'
import $ from 'jquery'

export const methods = {
  async reload() {
    this.loading = true

    console.debug('originalDenial=' + JSON.stringify(this.originalDenial))
    console.debug('architecturalSubmission=' + JSON.stringify(this.architecturalSubmission))

    if (this.originalDenial) {
      this.formData.denial = this.originalDenial.text
    }

    this.loading = false
  },

  open() {
    this.formData = {
      denial: ''
    }
    this.isOpen = true
    this.loading = false
  },

  closeModal() {
    this.$emit('update:toggle', (this.toggle = !this.toggle))

    // auto-close modal
    $('#clickclose').click()
  },

  async onFormSubmit() {
    if (
      !this.formData ||
      this.formData === undefined ||
      !this.architecturalSubmission ||
      !this.formData.denial
    ) {
      notifyError('There was a problem finalizing the denial reasons.')
      return
    }
    this.loading = true

    try {
      let finalDenials = []

      if (
        this.architecturalSubmission.denialReasons &&
        this.architecturalSubmission.denialReasons !== undefined
      ) {
        finalDenials = this.architecturalSubmission.denialReasons
      }

      finalDenials.push(this.formData.denial)

      console.debug('finalDenials=' + JSON.stringify(finalDenials))

      await archReqs.dispatch('put', {
        json: {
          hoaID: this.architecturalSubmission.hoaID,
          ownerID: this.architecturalSubmission.ownerID,
          architecturalSubmissionID: this.architecturalSubmission.architecturalSubmissionID,
          arTransactionID: this.architecturalSubmission.arTransactionID,
          submissionDocumentID: this.architecturalSubmission.submissionDocumentID,
          submissionDocumentDescription: this.architecturalSubmission.submissionDocumentDescription,
          architecturalCategoryID: this.architecturalSubmission.architecturalCategoryID,
          description: this.architecturalSubmission.description,
          detailedDescription: this.architecturalSubmission.detailedDescription,
          feeAmount: this.architecturalSubmission.feeAmount,
          wasApproved: this.architecturalSubmission.wasApproved,
          votingCompleteDate: this.architecturalSubmission.votingCompleteDate,
          estimatedCompletionDate: this.architecturalSubmission.estimatedCompletionDate,
          submissionDate: this.architecturalSubmission.submissionDate,
          respondByDate: this.architecturalSubmission.respondByDate,
          toBeCompletedBy: this.architecturalSubmission.toBeCompletedBy,
          contractor: this.architecturalSubmission.contractor,
          cancelDate: this.architecturalSubmission.cancelDate,
          cancelReason: this.architecturalSubmission.cancelReason,
          cancelledByAspNetUserID: this.architecturalSubmission.cancelledByAspNetUserID,
          denialType: this.architecturalSubmission.denialType,
          committeeID: this.architecturalSubmission.committeeID,
          stipulationsJSON: this.architecturalSubmission.stipulationsJSON,
          denialReasonsJSON: JSON.stringify(finalDenials)
        }
      })

      notifyMessage('These denials have been successfully finalized.')
      this.closeModal()
    } catch (e) {
      notifyError('There was a problem finalizing these denials.')
    }
  }
}

import _get from 'lodash/get'
import moment from 'moment'
import { archReqCategories } from '@/services/Architectural/ArchitecturalCategory/store'
import { notifyMessage, notifyProblem } from '@/services/notify'
import { storeArchDoc } from '@/services/Architectural/ArchitecturalSubmission/StoreDocument'
import { storeAttachments } from '@/services/Architectural/ArchitecturalSubmissionAttachment/sequences/storeAttachments'
import { archReqs } from '@/services/Architectural/ArchitecturalSubmission/store'
import { stringContains } from '@/utilities/String/contains'
import dataToInteger from '@/utilities/data/toInteger'
import _sortBy from 'lodash/sortBy'
import { archSettingsStore } from '@/services/Architectural/ArchitecturalSetting/store'

import { PRESET_FORM_VALUES } from './data'

export const preventionMessage = function(a) {
  const English = {
    1: `To continue, please select a category.`,
    2: `To continue, please specify a fee amount.`,
    3: `To continue, please write in the Work to be Done or Purpose of Request.`,
    4: `To continue, please provide a detailed description.`,
    5: `To continue, please choose an estimated completion date.`,
    6: `To continue, please select the owner that the request is made on behalf of.`,
    7: `To continue, please choose a submission date.`,
    8: `To continue, please choose a respond by date.`,
    9: `To continue, please specify who is to complete the work.`,
    10: `To continue, please specify the name of the contractor.`
  }

  return English[a]
}

export const methods = {
  filterOwners: function(query) {
    this.isFetching = true

    this.name = query

    this.filteredOwnersList = this.ownersList.filter(owner => {
      return stringContains(_get(owner, ['label'], ''), query, { ignoreCase: true })
    })

    this.isFetching = false
  },

  async loadArchSettingReviewFee() {
    console.debug('load loadArchSettingReviewFee...')
    await archSettingsStore
      .dispatch('getArchitecturalSettingById', {
        hoaID: this.currentHoaId
      })
      .then(({ result }) => {
        console.debug('returned result=' + JSON.stringify(result))
        if (result) {
          this.formData.feeAmount =
            result !== undefined &&
            result !== null &&
            result.reviewFee !== undefined &&
            result.reviewFee !== null &&
            result.reviewFee
              ? result.reviewFee.toString()
              : '0.00'
          console.debug('this.formData.feeAmount=' + this.formData.feeAmount.toString())
        }
      })
  },

  async sendRequest() {
    this.loading = true

    try {
      const mode = this.mode

      const architecturalCategoryID = this.formData.architecturalCategoryID

      let { feeAmount } = this.formData
      if (mode === 'homeowner') {
        feeAmount = '0'
      }

      const { description, detailedDescription } = this.formData
      const { utcFormat: estimatedCompletionDate } = this.$refs.estCompDate.getValue()

      const toBeCompletedBy = this.formData.toBeCompletedBy
      const contractor = this.formData.contractor

      const submissionDate = this.formData.submissionDate
      const respondByDate = moment()
        .add('7', 'days')
        .utcOffset(0, true)
        .toISOString()

      /*
      "toBeCompletedBy": "", // "homeowner" or "contractor"
      "contractor": ""
    */

      const json = {
        hoaId: this.currentHoaId,
        architecturalCategoryID,
        description,
        toBeCompletedBy,
        contractor,
        feeAmount,
        detailedDescription,
        submissionDate,
        estimatedCompletionDate,
        respondByDate
      }

      if (mode === 'manager') {
        const { ownerID } = this.getOwnerID()

        if (typeof ownerID !== 'number') {
          notifyProblem('A problem occurred while parsing the architectural request.')
          console.error('The ownerID does not appear to be accurate.', { ownerID })
          return
        }
        json.ownerID = ownerID
      } else if (mode === 'homeowner') {
        json.ownerID = this.currentOwnerId
      } else {
        console.error(`Invalid mode: ${mode}`)
        return
      }

      const { result, successful, message } = await archReqs.dispatch('CreateAndInitializeVotes', {
        json
      })
      if (!successful) {
        notifyProblem(message)
        this.loading = false
        return
      }

      const architecturalSubmissionID = _get(result, 'architecturalSubmissionID', '')
      if (typeof architecturalSubmissionID !== 'number' || architecturalSubmissionID < 1) {
        notifyProblem('An issue was encounted and the document and photos could not be uploaded.')
        this.loading = false
        return
      }

      const photos = this.photos
      const document = this.documents[0]

      if (Array.isArray(photos) && photos.length >= 1) {
        const { problems } = await storeAttachments({
          hoaID: this.currentHoaId,
          architecturalSubmissionID,
          attachments: photos
        })

        if (problems.length >= 1) {
          notifyProblem(`${problems.length} of the photos may not have been uploaded successfully`)
        }
      }

      if (this.documents !== undefined && document !== undefined && this.documents && document) {
        console.debug('in documents...' + document)
        const {
          successful: docSaved,
          message: docMessage,
          documentID: documentID
        } = await storeArchDoc({
          doc: document,
          params: {
            hoaID: this.currentHoaId,
            architecturalSubmissionID,
            description: document.name
          }
        })
        if (!docSaved || documentID <= 0) {
          notifyProblem(docMessage)
        }
      }

      notifyMessage('The architectural request was opened.')
    } catch (e) {
      console.debug('error=' + e)

      this.loading = false
      this.showing = false
    }

    await this.refresh()

    this.loading = false
    this.showing = false
  },
  updatePDF({ documents }) {
    console.debug('in updatePDF...' + documents)
    this.documents = documents
  },

  pdfOnClicked(index, pdf) {
    console.debug(index, pdf)
  },

  updatePhotos({ items }) {
    this.photos = items
  },
  getOwnerID() {
    if (!Array.isArray(this.ownersList)) {
      console.info('ownersList is not an array')
      return {
        ownerID: null
      }
    }

    if (this.ownersList.length === 0) {
      console.info('ownersList has not entries')

      return {
        ownerID: null
      }
    }

    const owner = this.ownersList.find(o => {
      return o.label === this.ownerFilterQuery
    })

    let ownerID = null
    try {
      const value = _get(owner, 'value', null)

      if (typeof value === 'string') {
        ownerID = dataToInteger(owner.value)
      } else {
        ownerID = value
      }
    } catch (x) {
      console.info(x)
    }

    return {
      ownerID
    }
  },
  checkPreventions() {
    const step = this.step

    if (step === 0) {
      this.isLastStep = false
      this.prevToolTip = ''
      this.prevPrevented = true

      // const { utcFormat: estimatedCompletionDate } = this.$refs.estCompDate.getValue()

      /*
        page 1
          both:
            architecturalCategoryID
            description (Work to be Done or Purpose of Request)
            detailedDescription
            estimatedCompletionDate
            
          manager
            ownerID
            feeAmount
            submissionDate
            respondByDate

          homeowner
      */

      const mode = this.mode
      const { architecturalCategoryID, description, toBeCompletedBy, contractor } = this.formData

      console.log({ contractor })

      if (mode === 'manager') {
        const { ownerID } = this.getOwnerID()
        if (typeof ownerID !== 'number') {
          this.nextToolTip = preventionMessage(6)
          this.nextPrevented = true
          return
        }
      }
      if (typeof architecturalCategoryID !== 'number') {
        this.nextToolTip = preventionMessage(1)
        this.nextPrevented = true
        return
      }
      if (typeof description !== 'string' || description.length === 0) {
        this.nextToolTip = preventionMessage(3)
        this.nextPrevented = true
        return
      }
      if (typeof toBeCompletedBy !== 'string' || toBeCompletedBy.length === 0) {
        this.nextToolTip = preventionMessage(9)
        this.nextPrevented = true
        return
      }
      if (toBeCompletedBy === 'contractor') {
        if (typeof contractor !== 'string' || contractor.length === 0) {
          this.nextToolTip = preventionMessage(10)
          this.nextPrevented = true
          return
        }
      }
      if (mode === 'manager') {
        const { feeAmount } = this.formData
        if (typeof feeAmount !== 'string' || feeAmount.length === 0) {
          this.nextToolTip = preventionMessage(2)
          this.nextPrevented = true
          return
        }
      }

      const { detailedDescription } = this.formData
      if (typeof detailedDescription !== 'string' || detailedDescription.length === 0) {
        this.nextToolTip = preventionMessage(4)
        this.nextPrevented = true
        return
      }

      const { estimatedCompletionDate, submissionDate, respondByDate } = this.formData
      if (typeof estimatedCompletionDate !== 'string' || estimatedCompletionDate.length === 0) {
        this.nextToolTip = preventionMessage(5)
        this.nextPrevented = true
        return
      }

      if (mode === 'manager') {
        if (typeof submissionDate !== 'string' || submissionDate.length === 0) {
          this.nextToolTip = preventionMessage(7)
          this.nextPrevented = true
          return
        }
        if (typeof respondByDate !== 'string' || respondByDate.length === 0) {
          this.nextToolTip = preventionMessage(8)
          this.nextPrevented = true
          return
        }
      }

      this.nextToolTip = ''
      this.nextPrevented = false
    } else if (step === 1) {
      this.nextToolTip = ''
      this.nextPrevented = false
      this.prevToolTip = ''
      this.prevPrevented = false

      this.isLastStep = false
    } else if (step === 2) {
      this.nextToolTip = ''
      this.nextPrevented = false
      this.prevToolTip = ''
      this.prevPrevented = false
    } else if (step === 3) {
      this.isLastStep = true

      this.nextToolTip = ''
      this.nextPrevented = true
      this.prevToolTip = ''
      this.prevPrevented = false
    }
  },
  nextStep() {
    this.step++
    this.checkPreventions()
  },
  prevStep() {
    this.step--
    this.checkPreventions()
  },

  empty() {
    this.step = 0

    this.ownerFilterQuery = ''
    this.detailedDescription = ''

    this.formData = {
      ...PRESET_FORM_VALUES
    }

    this.documents = []
    this.photos = []
  },

  // mode
  //  "homeowner"
  //  "manager"
  async show({ mode }) {
    this.mode = mode
    this.empty()

    if (this.mode === 'manager') {
      await this.$store.dispatch('hoa/loadDropDownOwnersList', {
        done: ({ list }) => {
          this.ownersList = list
          this.filteredOwnersList = list
          this.loading = false
        }
      })
    }

    this.checkPreventions()

    this.showing = true
  },
  async refresh() {
    const { list: categories } = await archReqCategories.dispatch('List', {
      hoaId: this.currentHoaId
    })

    if (!this.formData.feeAmount || this.formData.feeAmount === 0) {
      await this.loadArchSettingReviewFee()
    }

    this.categories = _sortBy(categories, 'name')
  }
}

import _get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'

export function data() {
  const authUserType = _get(this, ['$store', 'getters', 'user/authUserType'], null)

  const approvalModalPresets = {
    member: {},
    active: false,
    loading: true,
    stipulation: '',
    stipulationDeletions: [],

    //
    // maybe this shouldn't be a number??
    notesCount: 0,

    data: {
      note: '',
      stipulations: []
    }
  }

  const denialModalPresets = {
    active: false,
    loading: true,
    data: {
      note: '',
      reason: ''
    }
  }

  return {
    isConcludable: false,
    isApprovable: false,
    isDeniable: false,
    isDebug: true,
    toggle: false,
    toggleDenial: false,
    hoaID: 0,
    visible: false,
    architecturalRequestID: 0,
    mainPhoto: null,
    photosList: [],
    styles: {
      filterButton: {
        float: 'right',
        marginTop: '4px'
      }
    },
    authUserType,

    loading: {
      requestSpecifications: true,
      committee: true,
      voteDetails: true,
      doc: true,
      photos: true
    },
    results: {
      yes: 0,
      no: 0,
      pending: 0
    },
    requestDetails: {
      wasApproved: '?'
    },

    voteDetailsTab: 0,

    approvalModal: cloneDeep(approvalModalPresets),
    approvalModalPresets,
    denialModal: cloneDeep(denialModalPresets),
    denialModalPresets,

    committeeMembers: [],

    cols: 2,
    stipulationList: [],
    denialList: [],
    selectedStipulation: null,
    selectedDenial: null,
    currentArchitecturalSubmission: null,
    finalStipulations: [],
    finalDenials: [],

    voteDetailsCheckboxes: ['showDenialNotes', 'showApprovalNotes', 'reasonForDenial'],

    voteDetailSortOptions: ['Approved ↑', 'Denied ↑'],
    voteDetailSorting: null
  }
}

<template>
  <div arch-req-attach-modal>
    <div class="section">
      <div class="container">
        <div v-if="onlyPhotos && onlyPhotos === true" class="is-size-4">
          {{ `${title} Architectural Request Photo` }}
        </div>
        <div v-else class="is-size-4">{{ `${title} Architectural Request Document` }}</div>
        <div class="box is-12">
          <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <transition name="fade" mode="out-in" appear>
              <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
                <fieldset>
                  <div class="is-12">
                    <valid-input
                      style="width:50px !important;"
                      name="Description"
                      label="Description"
                      type="textarea"
                      vid="Description"
                      placeholder="Description"
                      spellcheck="true"
                      aria-label="Description"
                      rules="max:100|required"
                      class="is-small"
                      v-model="description"
                    />
                  </div>
                </fieldset>
                <fieldset>
                  <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                    <div class="field">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </fieldset>
              </form>
            </transition>
          </ValidationObserver>
          <div class="box is-8">
            <section>
              <div v-if="base64pdf" class="has-text-centered">
                <div>
                  <div>
                    <div
                      v-dragscroll="true"
                      :class="['doc-viewer', { 'zoom-active': scale > 0.9 }]"
                    >
                      <pdf
                        style="display: none;"
                        :src="base64pdf"
                        @num-pages="pageCount = new Array($event)"
                      />
                      <!-- Actual document -->
                      <pdf
                        v-for="(page, index) in pageCount"
                        :key="index"
                        :src="`${base64pdf}`"
                        :page="index + 1"
                        :scale="scale"
                        :resize="true"
                        :style="{ margin: 'auto', width: `${100 * scale}%` }"
                      />
                    </div>
                  </div>
                  <div class="doc-controls has-text-center pt-6">
                    <b-field position="is-centered">
                      <div class="control">
                        <b-button @click="zoom(-1)">-</b-button>
                      </div>
                      <div class="control">
                        <b-button @click="zoom(1)">+</b-button>
                      </div>
                      <div class="pl-6 control">
                        <b-button @click="downloadPdf"
                          ><svg
                            class="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                            ></path></svg
                        ></b-button>
                      </div>
                    </b-field>
                  </div>
                </div>
              </div>
              <div v-else-if="imageContent">
                <img :src="imageContent" />
              </div>
              <div class="pt-5" v-if="!documentViewOnly && !architecturalSubmissionAttachment">
                <!-- Show the Upload Form -->
                <b-field class="file">
                  <b-upload v-model="file" drag-drop :accept="uploadMimeTypes">
                    <section class="section has-width-500">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon icon="file" size="is-large"> </b-icon>
                        </p>

                        <p v-if="file">
                          {{ file.name }}
                        </p>
                        <p v-else>
                          Drop {{ uploadMimeText }} file here, or click to select a file from your
                          computer.
                        </p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>
              </div>

              <div class="pt-5">
                <span
                  class="pr-5"
                  v-if="
                    attachment == false && !architecturalSubmissionAttachment && !documentViewOnly
                  "
                  ><b-button type="is-primary" :disabled="!file" @click.prevent="submitFile"
                    >Upload</b-button
                  ></span
                >
                <span
                  v-if="
                    architecturalSubmissionAttachment && attachment == true && !documentViewOnly
                  "
                  class="pt-5 pr-5"
                >
                  <b-button type="is-primary" @click.prevent="updateFile">Update</b-button>
                </span>
                <span><b-button type="is-primary" @click.prevent="leaveForm">Close</b-button></span>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState } from 'vuex'
import { dragscroll } from 'vue-dragscroll'
import pdf from 'vue-pdf'
import { methods } from './keys/methods'
import { data } from './keys/data'

// components
import ValidInput from '@/components/inputs/ValidInput'

export default {
  props: {
    architecturalSubmissionID: Number,
    architecturalSubmissionAttachment: Object,
    documentViewOnly: Boolean,
    onlyPhotos: Boolean
  },

  directives: { dragscroll },

  components: {
    pdf,
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  mounted() {
    this.description = ''
    this.reload()
  },

  watch: {
    reloadDocuments() {
      this.reload()
    }
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  data,
  methods
}
</script>

<style lang="scss">
#arch-req-attach-document-modal .modal-h-full {
  left: 50%;
  transform: translate(-50%);
}

#request-image-upload {
  .upload .upload-draggable {
    border: none 0;
    border-radius: 0;
    width: 100%;
    padding: 0;
  }

  .modal-close,
  .delete {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    margin: 5px 5px 0 0;
  }
}

#arch-req-attach-document-modal .doc-viewer {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 130%;
  min-height: 40vh;
  max-height: calc(100vh - 320px);
  border: 1px solid #dbdbdb;

  &.zoom-active {
    cursor: grab;
  }
}

#arch-req-attach-document-modal .doc-controls {
  margin-top: -1em;
}
</style>

<template>
  <SpecificationPanel :label="label">
    <span v-if="!editMode" :style="Object.assign({}, { display: 'inline-block' }, textStyles)">{{
      parsedDate
    }}</span>
    <DatePicker v-if="editMode" v-model="date" :ariaLabel="label" />
  </SpecificationPanel>
</template>

<script>
import SpecificationPanel from './SpecificationPanel'

import DatePicker from '@/components/inputs/DatePicker'
import { parseDate } from '@/utilities/Date/parse'

const dateFormat = 'LL'

export default {
  components: {
    DatePicker,
    SpecificationPanel
  },
  props: {
    label: String,
    labelStyles: {
      type: Object,
      default: () => {
        return {}
      }
    },
    text: [String, Number],
    textStyles: {
      type: Object,
      default: () => {
        return {}
      }
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      date: this.text
    }
  },
  watch: {
    text(value) {
      this.date = value
    }
  },
  computed: {
    parsedDate() {
      return parseDate(this.text, dateFormat)
    }
  },
  methods: {
    getValue() {
      return this.date
    }
  }
}
</script>

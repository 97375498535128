<template>
  <div>
    <b-field label="Photos"></b-field>

    <div :style="{ border: '1px solid rgb(226, 232, 240)', borderRadius: '4px', padding: '20px' }">
      <b-field class="file">
        <!-- accept="image/jpeg" -->

        <b-upload v-model="items" multiple drag-drop expanded :accept="accept">
          <section class="section">
            <div class="content has-text-centered">
              <p>
                <b-icon icon="upload" size="is-large" />
              </p>
              <p>Drop your photos here or click to upload</p>
            </div>
          </section>
        </b-upload>
      </b-field>

      <ul id="gallery" class="flex flex-1 flex-wrap">
        <li
          no-photos
          v-show="previews.length === 0"
          class="h-full w-full text-center flex flex-col items-center justify-center items-center"
        >
          <img
            class="mx-auto w-32"
            src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
            alt="no data"
          />
          <span class="text-small text-gray-500">No photos selected</span>
        </li>

        <li
          v-for="(image, index) in previews"
          :key="index"
          class="flex pr-2"
          :style="{
            position: 'relative',
            width: '23%'
          }"
        >
          <PictureFrame :src="image" :label="items[index].name" />

          <button
            :style="{ position: 'absolute', top: '10px', left: '10px' }"
            class="delete"
            type="button"
            @click="erase(index)"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
/*
  import PhotoUploader from '@/components/Fields/PhotoUploader'

  <PhotoUploader 
    :update="photosUpdated"
  />

  photosUpdated ({ items }) {
  
  }
*/

/*
  Considerations?
    .pdf
    .doc
    .docx
*/

import PictureFrame from '@/components/Picture/Frame'

export default {
  components: {
    PictureFrame
  },
  props: {
    update: Function,
    addFile: File,
    accept: {
      type: String,
      default: 'image/png, image/jpeg, image/jpg'
    }
  },
  data() {
    return {
      items: [],
      previews: []
    }
  },

  watch: {
    async items() {
      this.previews = this.updatePreviews({
        items: this.items
      })

      this.update({
        items: this.items
      })
    },
    async addFile() {
      this.items.push(this.addFile)
    }
  },

  methods: {
    async erase(index) {
      this.items.splice(index, 1)
      this.previews.splice(index, 1)
    },

    updatePreviews({ items }) {
      if (!Array.isArray(items)) {
        console.error('previews could not be updated')
        return
      }

      return items.map(item => {
        console.log('item=' + item)
        return URL.createObjectURL(item)
      })
    }
  }
}
</script>

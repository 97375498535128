/*
  example:
    import { storeArchAttachment } from '@/services/Architectural/ArchitecturalSubmissionAttachment/StoreAttachment';

    const { successful, message } = await storeArchAttachment ({
      attachment,
      params: {
				hoaID,
				architecturalSubmissionID
      }
    });
    if (!successful) {
      console.error (message);
      return;
    }
    
    
*/
//
import kms from '@/services/kms'
//
export async function storeArchAttachment({ attachment, params }) {
  let message = `The architectural attachment could not be uploaded.`

  try {
    const formData = new FormData()
    formData.append('file', attachment)

    let path = `/Architectural/ArchitecturalSubmissionAttachment/StoreAttachment`
    let paramCount = 0
    for (const param in params) {
      path += paramCount === 0 ? `?` : `&`
      path += param
      path += '='
      path += params[param]
      paramCount++
    }

    await kms.post(path, formData, {
      'Content-Type': 'multipart/form-data'
    })

    return {
      success: true,
      message: ''
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message
  }
}

///////
